.news{
    &-box{
        background-color: #ECE4E2;
        padding: 2em 3em;

        &-title{
            justify-content: space-between;
            border-top: none !important;
            border-bottom: 1px solid #CEC9AC;

            padding-top: 0 !important;
            padding-bottom: 0.75em;
            margin-top: 0;

            li{
                & + li{
                    flex: none;
                    margin-left: 1em;
                    padding-left: 2em;
                }
            }

            @include setMinRwd{
                h3{
                    @include fontSize(36px);
                }
            }

        }

        &-article{
            padding: 1em;
        }

        @include setMaxRwd{
            padding: 1em;
            &-article{
                padding-left: 0.5em;
                padding-right: 0.5em;
            }
        }
    }

    // 給活動
    &Act{
        .card{
            background-color: #ECE4E2;
            box-shadow: none;
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            &-img-top{
                border-top-right-radius: 0;
                border-bottom-left-radius: 0
            }
        }

        &-photo{
            .swiper-slide{
                overflow: hidden;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                @include setMinRwd{
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                }
            }

            .swiper-button{
                &-next,
                &-prev {
                    color: #000;
                    &::after{
                        font-size: 1.25em;
                        font-weight: 900;
                    }
                    @include setMinRwd{
                        &::after{
                            font-size: 1.75em;
                        }
                    }
                }
                
                @include setMaxRwd{
                    &-next{
                        right: -10px;
                    }

                    &-prev {
                        left: -10px;
                    }
                }
            }

            .swiper-slide-ratio{
                // --bs-aspect-ratio: 65.5%;
                iframe{
                    height: 100%;
                }
            }

            // 大圖
            .mySwiper2 {
                height: 80%;
                width: 100%;

                .swiper-wrapper {
                    display: flex;
                    align-items: center;
                }
            }
            
            // 小圖
            &-small{
                padding-left: 1.5em;
                padding-right: 1.5em;
                margin-top: 0.3em;
                @include setMinRwd{
                    padding-left: 4em;
                    padding-right: 4em;
                    margin-top: 25px;
                }
            }

            .mySwiper {
                height: 20%;
                padding: 10px 0;

                .swiper-slide {
                    width: 25%;
                    opacity: 0.4;
                    // height: 100%;

                    height: 0;
                    padding-bottom: 12%;
                    img{
                        @extend %imgCenter;
                    }

                    &-thumb-active {
                        opacity: 1;
                    }

                    @include setMaxRwd{
                        padding-bottom: 15%;
                    }

                    @include mobile{
                        padding-bottom: 25%;
                    }
                }
            }
        }
    }
}