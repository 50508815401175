.about{
    &_article{
        margin-bottom: 2.5em;

        @include setMinRwd{
            margin-bottom: 5em;
        }
    }

    &_txt{
        @include setMinRwd{
            line-height: 2;
            padding-left: 3rem;
            padding-right: 1.5rem;
        }
    }
}