.index{
    &-banner{
        width: 100%;
        overflow: hidden;
        margin-top: get-vw(135px);
        .swiper{
            &-pagination{
                &-bullets {
                    bottom: 0 !important;
                    @include setMinRwd{
                        width: auto !important;
                        left: auto !important;
                        bottom: auto !important;
                        right: 1.5em;
                        top: 50%;
                        transform: translate3d(0px, -50%, 0);
                        border: #fff 1px solid;
                        padding: 0.5em;
                        border-radius: 1em;
                    }

                    @include setMaxRwd{
                        bottom:-1.5em !important
                    }
                }

                &-bullet {
                    width: 12px !important;
                    height: 12px !important;
                    background-color: #000;
                    &-active{
                        background-color: $colorMain !important;
                        border-radius: 0.75em !important;
                    }
                    
                    @include setMinRwd{
                        display: block;
                        margin: 0.3em 0 !important;
                        transition: height 200ms ease-in;
                        background-color: #fff;
                        opacity: 1;
                        &-active{
                            height: 1.75em !important;
                        }
                    }

                    @include setMaxRwd{
                        &-active{
                            width: 1.75em !important;
                            margin: 0 0.3em !important;
                        }
                    }
                }
            }

            // 內容
            &-slideInfo{
                width: 100%;

                // 圖片
                .imgInfo{
                    @include setWidthRwd(1025px,1100px){
                        img{
                            height: 20em;
                            @include imgObject(cover,left);
                        }
                    }

                    @include setMaxRwd{
                        img{
                            height: 15em;
                            @include imgObject(cover,center);
                        }
                    }

                    @include mobile{
                        img{
                            height: 7em;
                        }
                    }
                }

                // 文字
                .txtInfo{
                    display: flex;
                    align-items: flex-start;
                    width: 100%;
                    max-width: 1700px;

                    position: absolute;
                    bottom: 0%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                    
                    .title{
                        @include fontSize(30px);
                        line-height: 1;
                        margin-bottom: 15px;
                    }

                    .txt{
                        @include fontSize(36px);
                        line-height: 1.5;
                    }

                    p{
                        color:#fff;
                        font-weight: 900;
                    }
                }

                // 裝飾
                .txtStyle{
                    top: 5.5em;
                }

                .imgStyle{
                    top: 1em;
                }

                @include setStyleRwd(1500px){
                    // 裝飾
                    .txtStyle{
                        padding-left: 15px;
                    }
                }

                @include setMaxRwd{

                    .imgStyle{
                       display: none;
                    }
                }

                @include pad{
                    .txtInfo{
                        .txt{
                            @include fontSize(30px);
                        }

                        .title{
                            &,
                            .text-inner{
                                margin-bottom: 0.25em;   
                            }
                        }
                    }

                    // 裝飾
                    .txtStyle{
                        top: 0;
                    }
                }
            }
        }
        
        @include setMaxRwd{
            margin-top: 11.5em;
        }
        
        @include pad{
            margin-top: 110px;
        }
    }

    // 內框
    &-content{
        padding: 1em;
        @include setWidthRwd(1100px,1500px){
            padding-left: 2em;
            padding-right: 2em;
        }
    }

    // 頁面使用
    &-page{
        &About{
            &::after{
                content: '';
                display: block;
                @include setSize(100%,100%);
                max-width: 400px;
                min-height: 1005px;
                @include setBackSet(right,contain);
                background-image: url('../images/index-sideImg.png');
                position:absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                mix-blend-mode: darken;

                @include setMaxRwd{
                    opacity: 0.1;
                }
            }
        }

        &SerList{
            &-wrap{
                @extend %setFlex;
                justify-content: center;
                @include setMinRwd{
                    margin-bottom: 50px;
                }
            }

            &-item{
                flex:0 0 20%;
                max-width: 20%;
                text-align: center;
                padding: 0 15px;
                margin-bottom: 1.5em;

                .title{
                    @include fontSize(19px);
                    line-height: 1.45;
                    margin-top: 10px;
                }

                @include setMaxRwd{
                    flex: 0 0 25% !important;
                    max-width: 25% !important;
                    padding: 0 1em;
                    margin-bottom: 1.25em;
                }

                @include pad{
                    flex: 0 0 33% !important;
                    max-width: 33% !important;
                }

                @include mobile{
                    flex: 0 0 50% !important;
                    max-width: 50% !important;
                }
            }

            &-article{
                &.top{
                    max-width: 72em;
                    .index-pageSerList-item{
                        flex:0 0 calc(100% / 6);
                        max-width: calc(100% / 6);
                    } 

                    @include setWidthRwd(1100px,1300px){
                        max-width: 60em;
                    }
                }

                &.bottom{
                    max-width: 87em;
                   .index-pageSerList-item{
                    flex:0 0 calc(100% / 7);
                    max-width: calc(100% / 7);
                   } 
                }
            }
        }

        &News{
            @include setMinRwd{
                margin-bottom: 1em;
            } 
        }

        &Act{
            @include setMinRwd{
                margin-bottom: 150px;
            }
        }
    }

    &News-swiper,
    &Act-swiper{
        position: relative;
        padding-left: 1em;
        padding-right: 1em;
        margin-bottom: 1em;

        .card-wrap{
            width: 100%;
            margin-top: 0 !important;
        }

        .swiper{
            &-button{
                &-disabled{
                    opacity: 1 !important;
                    background-color: $colorMain;
                }
                
                &-prev,
                &-next{
                    @include setSize(65px,65px);
                    @include radius(50%);
                    border: $colorMain 1px solid;
                    &::after{
                        display: none;
                    }

                    &::before{
                        content: '';
                        display: block;
                        @include setSize(43px,17px);
                        @include setBackSet(center,contain);
                        background-image: url('../images/swiper-btn-default.png');
                    }

                    &.swiper-button-disabled::before{
                        background-image: url('../images/swiper-btn-active.png');
                    }

                    @include setMaxRwd{
                        @include setSize(35px,35px);
                        &::before{
                            @include setSize(20px,15px);
                        }
                    }
                }

                &-prev{
                    @include setMaxRwd{
                        left: -10px;
                    }
                }

                &-next{
                    &::before,
                    &.swiper-button-disabled::before{
                        transform: rotateY(180deg);
                    }
                    @include setMaxRwd{
                        right: -10px;
                    }
                }
                
            }
        }

        &.full{
            .act{
                .card{
                    &-img-top{
                        height: auto;
                        padding-bottom: 0;
                        img{
                            position: static;
                        }
                    }
                }
            }
        }

        @include setMinRwd{
            padding-left: 7em;
            padding-right: 7em;
        }
        
        @include setMaxRwd{
            padding-left: 1.5em;
            padding-right: 1.5em;

            &.full{
                padding-left: 0;
                padding-right: 0;
            }

            .card-wrap,
            .card{
                margin-bottom: 0;
            }

            .card{
                max-width: calc(100% - 1em);
            }
        }

        @include setWidthRwd(1020px,1100px){
            &.full{
                .act{
                    .card__horizontal {
                        flex-direction: row;
                    }
                }
            }
        }
    }

    &News-swiper{
        .swiper{
            &-slide{
                &:nth-child(3n+1){
                    .card{
                        background-color: #ECE4E2;
                    }
                }
                &:nth-child(3n+2){
                    .card{
                        background-color: #E7EBDA;
                    }
                }
                &:nth-child(3n+3){
                    .card{
                        background-color: #FDEAE3;
                    }
                }
            }
        }
    }
}

// 圓圈圈
.circle {
    &-wrapper{
        position: relative;
        width: 100%;
        padding-top: 100%;
        border-radius: 50%;
        @include setBackSet(center,100%);
        background-image: url('../images/indexSer-bg.jpg');
    }
    
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    @extend %setFlex-center;
    justify-content: center;
    flex-direction: column;

    *{
        position: relative;
        z-index: 2;
    }
    .num{
        @include setSize(40px,40px);
        line-height: 40px;
        text-align: center;
        color: #fff;
        background-color: $colorItem;
        @include radius(50%);

        @include setMaxRwd{
            margin-bottom: 0.25em;
        }
    }
}

.animationItem {
    transform: translate3d(-50px, 0, 0);
    opacity: 0;
    transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}
  
.animationItem.animated {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}