$card-left: 1em;
$card-right: 1em;

$mobile-card-left: 0.5em;
$mobile-card-right: 0.5em;

.card{
    flex-grow: 1;
    margin-left: $card-left;
    margin-right: $card-right;
    @include radius(20px);
    overflow: hidden;

    // Grid
    &__3{
        @include setMinRwd{
            max-width: calc(100% / 3 - ($card-left + $card-right));
            flex: 0 0 calc(100% / 3 - ($card-left + $card-right));
        }
    }

    &__4{
        @include setMinRwd{
            max-width: calc(100% / 4 - ($card-left + $card-right));
            flex: 0 0 calc(100% / 4 - ($card-left + $card-right));
        }
    }

    &__horizontal{
        @include setMinRwd{
            flex-direction: row;
        }
    }
    // End

    &-wrap{
        @extend %setFlex;
        margin-bottom: 1em;
        @include setMinRwd{
            margin-top: 1em;
        }
    }

    &-img-top{
        img{
            display: block;
            width: 100%;
        }
    }

    &-body{
        padding-bottom: 0;
    }

    &-title{
        text-align: left;
        line-height: 1.45;
    }

    &-time{
        font-family: $Roboto;
        .date{
            @include fontSize(30px);
            font-weight: 600;
        }

        .year{
            margin-top: 3px;
            .icon{
                margin-top: -7px;
                margin-right: 0.3em;
            }

            span{
                @include fontSize(18px);
                color: #585757;
                font-weight: lighter;
                font-family: $Roboto;
            }
        }
    }
    
    &-btn-list{
        @extend %setFlex-center;
        margin-top: 1em;
        li{
            flex: 1;
            text-align: center;
        }
    }

    @include setMinRwd{
        &-text{
            height: 3em;
            overflow: hidden;
        }

        &-btn-list{
            li{
                & + li{
                    border-left: 1px solid #CEC9AC;
                    padding-left: 1em;
                }
            }
        }
    }

    @include setMaxRwd{
        margin-left: $mobile-card-left;
        margin-right: $mobile-card-right;

        max-width: calc(50% - ($mobile-card-left + $mobile-card-right));

        &-wrap{
            margin-left: - $mobile-card-left;
            margin-right: - $mobile-card-right;  
        }

        &-body{
            display: flex;
            flex-direction: column;
            padding: 0.25em 0.5em;
        }

        &-title {
            @include fontSize(20px)
        }

        &-text{
            @include fontSize(16px)
        }

        &-time{
            margin-bottom: 0.25em;
        }

        &-caption {
            margin-top: 0.25em;
            margin-bottom: 0.25em;
        }

        &-btn{
            .btn{
                display: block;
                width: 100%;
            }
        }
    }
}

.news{
    .card{
        margin-bottom: 2em;
        &:nth-child(3n+1){
            background-color: #ECE4E2;
        }
        &:nth-child(3n+2){
            background-color: #E7EBDA;
        }
        &:nth-child(3n+3){
            background-color: #FDEAE3;
        }

        &-img-top{
            height: 0;
            padding-bottom: 65%;
            overflow: hidden;
            img{
                @extend %imgCenter;
                width: auto;
                max-height: none;
            }
        }

        &-caption{
            padding: 0.25em;
            @include setMinRwd{
                padding-top: 1.25em;
                padding-bottom: 1em;
                padding-left: 2em;
                padding-right: 2em;
            }
        }

        &-btn-list{
            border-top: 1px solid #CEC9AC;

            @include setMinRwd{
                padding-left: 1em;
                padding-right: 1em;
                border-bottom: 1px solid #CEC9AC;
            }

            @include setMaxRwd{
                padding-top: 0.75em;
            }
        }

        &-time{
            @include setMaxRwd{
                display: flex;
                justify-content: space-between;
                .date{
                    @include fontSize(25px);
                }
                .year{
                    margin-top: 0;
                }
            }
        }
        
        &-btn-list {
            @include setMaxRwd{
                padding-bottom: 0.6em !important;
                margin-top: auto;
                li{
                    flex: auto;
                    width: 100%;

                    & + li {
                        padding-left: 0;
                    }
                }
            }
        }
    }
}

.act{
    .card{
        box-shadow: 0 0 30px rgba(0,0,0,0.15);
        @include radius(40px);
        border-top-left-radius: 0;
        border-bottom-right-radius: 0;
        border:none;
        margin-bottom: 2em;
        
        &-img-top{
            border-top-right-radius: 40px;
            border-bottom-left-radius: 40px;
            overflow: hidden;

            height: 0;
            padding-bottom: 70%;
            img{
                @extend %imgCenter;
                width: auto;
                max-height: none;
            }
        }

        &-body{
            padding: 1.5em;
        }

        // 水平狀態
        &__horizontal{
            box-shadow: none;
            margin-bottom: 1.25em;
            background-color: #ECE4E2;
            .card-img-top {
                border-top-left-radius: 0;
                @include setMinRwd{
                    flex: 0 0 680px;
                }
            }
            .card-body{
                @include setMinRwd{
                    padding: 3em 4em 2em
                }
            }
        }

        @include setMaxRwd{
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        }
    }

    .btn-outline-secondary{
        border-color: #6F6E6C;
        color: #6F6E6C;
    }
}