.numCirlce{
    display: inline-block;
    @include setSize(40px,40px);
    line-height: 37px;
    @include fontSize(23px);
    @include setBackSet(center,contain);
    background-image: url('../images/icon-num.png');
    @include radius(50%);
    color: #fff;
    text-align: center;
    margin-right: 15px;
    overflow: hidden;
}

.ser{

    // 連結
    &_link{

        // 框 & grid
        &_wrap{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 1em;

            @include setMinRwd{
                margin-left: -20px;
                margin-right: -20px;
                margin-bottom: 3em;
            }
        }
        flex: auto;

        // 樣式
        display: block;
        background-color: $colorMain;
        @include fontSize(22px);
        line-height: 1.2;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        text-align: center;
        padding: 15px 30px 17px;
        margin: 0 15px 15px;
        &,
        &:hover,
        &:focus{
            color:#fff;
        }

        &:hover{
            background-color: $colorSubs;
        }

        @include setMaxRwd{
            flex: 1 0 calc(50% - 10px);

            @include fontSize(18px);
            line-height: 1.5;
            padding-left: 5px;
            padding-right: 5px;
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    // 內容區塊
    &_article{
        margin-bottom: 2.5em;

        @include setMinRwd{
            margin-bottom: 5em;
        }
    }

    &_txt{
        display: flex;
        padding: 1em;

        @include setMinRwd{
            padding-left: 65px;
            padding-right: 10px;
            padding-top: 30px;
            padding-bottom: 15px
        }

        // 文字+數量
        &_item{
            @extend %setFlex;
            align-items: flex-start;
            @include fontSize(20px);

            .num{
                flex: none;

                display: inline-block;
                color: #6d6e6c;
                font-family: $Roboto;
                font-weight: 900;
                border-bottom: 1px solid #838482;
                padding-bottom: 10px;
                margin-right: 20px;
            }

            .txt{
                flex: 1;

                color: #272626;
                line-height: 1.6;
                border-bottom: 1px dashed #b9beb5;
                padding-top: 2px;
                padding-bottom: 20px;
                margin-bottom: 0.5em;
            }
        }
        
        // 框
        &_list{
            padding-right: 1em;
            @include setMinRwd{
                flex: 1;
                padding-right: get-vw(70px);
            }

            &.half{
                @include setMinRwd{
                    padding-right: get-vw(35px);
                }
            }
        }

        &_img{
            text-align: center;
            @include setMinRwd{
                flex: none;
                width: 190px;
            }
        }

        @include setMaxRwd{
            flex-direction: column;
            padding-top: 0;
            &_img{
                order:-1;
                margin-bottom: 15px;
            }
        }
    }
}