@charset "UTF-8";
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");
@import url("//fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("//fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
a, body {
  color: #3f3f3f;
  font-size: 18px;
  font-family: "Noto Sans TC", "Microsoft JhengHei", "微軟正黑體", "Arial", sans-serif;
  -webkit-text-stroke: 0.1px;
  line-height: 1.65;
  font-weight: 400;
}

.sprite, input, form, body, html {
  margin: 0px;
  padding: 0px;
}

.dowload-list li, .dowload-item, .ser_txt_item, .index-pageSerList-wrap, .card-wrap, .form-group-list, .pagination-wrap, .content-nav-dropdown-container, .mainContent, footer .footer-info-wrap, footer .wrapper-1550 {
  display: flex;
  flex-wrap: wrap;
}

.circle, .form-flex, .card-btn-list, .btn-wrap, .radio_style, .checkbox_style, .swiper-effects, .content-item, .heading-title, .title-mainHeadingPage, .page-title-content, footer .footer-icon, .pageloading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.newsAct-photo .mySwiper .swiper-slide img, .act .card-img-top img, .news .card-img-top img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
  margin: auto;
}

.font-size-1 {
  font-size: 87.5% !important;
}

.font-size-2 {
  font-size: 100% !important;
}

.font-size-3 {
  font-size: 112.5% !important;
}

.font-size-4 {
  font-size: 150% !important;
}

.font-size-5 {
  font-size: 175.5% !important;
}

.font-size-6 {
  font-size: 250% !important;
}

.font-size-7 {
  font-size: 275% !important;
}

.font-size-8 {
  font-size: 362.5% !important;
}

.font-size-9 {
  font-size: 450% !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.25 !important;
}

.line-height-3 {
  line-height: 1.5 !important;
}

.line-height-4 {
  line-height: 1.75 !important;
}

.line-height-5 {
  line-height: 2 !important;
}

.noJS > div,
.noJS > ul {
  display: none;
}

.noJS > noscript {
  display: block;
  width: 100%;
  line-height: 10em;
}

* {
  box-sizing: border-box;
}

html {
  overflow: -moz-scrollbars-vertical;
}

body {
  background: #fff;
  visibility: visible;
  overflow-x: hidden;
}
body.fixed {
  overflow: hidden;
}
@media (max-width: 1600px) {
  body {
    font-size: 18px;
  }
}

div {
  position: relative;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.sh_sans {
  font-family: "source-han-sans-traditional", "微軟正黑體", sans-serif;
}

.sh_serif {
  font-family: "source-han-serif-tc", serif;
}

form {
  font-size: 16px;
}

label {
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

input {
  -webkit-autofill: unset;
  font-size: 16px;
  background-color: #eeeeee;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fcfcfc inset;
}
input:focus {
  outline: 0;
}
input[type=text], input[type=password] {
  vertical-align: top;
}
input[type=radio], input[type=checkbox] {
  margin-right: 5px;
  cursor: pointer;
  vertical-align: middle;
}
input[type=number]::-webkit-inner-spin-button,
input [type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input.hid {
  display: none;
}

a:focus,
input:focus,
select:focus,
button:focus {
  box-shadow: none !important;
  outline: none;
}

select {
  border: 0px;
  outline: none;
}

textarea {
  padding: 5px;
  border: 1px solid rgba(216, 216, 216, 0.8);
  box-sizing: border-box;
  resize: none;
  font-size: 16px;
  background-color: #eeeeee;
}

a {
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
  color: currentColor;
}

img {
  max-width: 100%;
}

.sprite {
  fill: #69b748;
  vertical-align: top;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.font-button .far,
.font-button .fas {
  color: #666;
  transition: 0.08s cubic-bezier(0.37, 0.99, 0.92, 0.96);
}
.font-button:hover .far,
.font-button:hover .fas {
  color: #69b748;
}

.zero-size {
  font-size: 0px;
  letter-spacing: 0px;
  word-spacing: 0px;
}

.required {
  color: #69b748;
  margin: 0 0 0 8px;
}

.red {
  color: #ff0016;
}

.space {
  display: inline-block;
  width: 7px;
}

.clear {
  clear: both;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  overflow: hidden;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide {
  display: none !important;
}
@media (max-width: 1024px) {
  .hide {
    display: block !important;
  }
}

@keyframes menushow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes visitsLoad {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  30% {
    transform: translate3d(-70%, 0, 0);
  }
  80% {
    transform: translate3d(-20%, 0, 0);
  }
  95% {
    transform: translate3d(-5%, 0, 0);
  }
  100% {
    transform: translate3d(0%, 0, 0);
  }
}
@keyframes visitsShow {
  0% {
    opacity: 0;
    transform: translate3d(0, 45px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes moveCircle {
  0%, 100% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(-5%);
  }
}
.table {
  border-top: 1px solid #c8dae8;
  border-right: 1px solid #c8dae8;
  border-left: 1px solid #c8dae8;
}

.input {
  width: 100%;
}

.textarea {
  width: 100%;
  height: 100px;
}

.searchBar {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.icon {
  display: inline-block;
  vertical-align: middle;
}

.mw-auto {
  max-width: none !important;
}

.wrapper-1800 {
  width: 100%;
  max-width: 1800px;
  margin: auto;
}

.wrapper-1750 {
  width: 100%;
  max-width: 1750px;
  margin: auto;
}

.wrapper-1700 {
  width: 100%;
  max-width: 1700px;
  margin: auto;
}

.wrapper-1650 {
  width: 100%;
  max-width: 1650px;
  margin: auto;
}

.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: auto;
}

.wrapper-1550 {
  width: 100%;
  max-width: 1550px;
  margin: auto;
}

.wrapper-1500 {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.wrapper-1450 {
  width: 100%;
  max-width: 1450px;
  margin: auto;
}

.wrapper-1400 {
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.wrapper-1350 {
  width: 100%;
  max-width: 1350px;
  margin: auto;
}

.wrapper-1300 {
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.wrapper-1250, .nav-tabs.main {
  width: 100%;
  max-width: 1250px;
  margin: auto;
}

.wrapper-1200 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.wrapper-1100 {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.wrapper-1050 {
  width: 100%;
  max-width: 1050px;
  margin: auto;
}

.wrapper-1000 {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.wrapper-900 {
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.wrapper-800 {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.detail_label {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  padding: 0 6px;
  height: 20px;
  text-align: center;
  background-color: #fff;
  line-height: 20px;
  vertical-align: middle;
  font: 13px/25px Arial;
  border: 1px solid #fff;
}
.detail_label.fc-LightSalmon {
  border-color: #e9a3a8;
  color: #e9a3a8;
  line-height: 20px;
}

.pageloading {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  flex-direction: column;
  justify-content: center;
  transition: 0.2s 0.2s;
  z-index: 10000;
}
.pageloading .loading {
  width: 200px;
  height: 8px;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 5px;
  overflow: hidden;
}
.pageloading .loading .bar {
  width: 0%;
  height: 100%;
  background-color: #69b748;
}
.pageloading.compelet {
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

@keyframes jumping {
  50% {
    top: 0;
  }
  100% {
    top: -50px;
  }
}
@keyframes eyemove {
  0%, 10% {
    transform: translateX(50%);
  }
  90%, 100% {
    transform: translateX(-50%);
  }
}
.letter {
  float: left;
  font-size: 18px;
  font-size: 1.125rem;
  color: #000;
}

.letter-holder .letter {
  animation-name: loadingF;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}

.l-1 {
  animation-delay: 0.48s;
}
.l-2 {
  animation-delay: 0.53s;
}
.l-3 {
  animation-delay: 0.58s;
}
.l-4 {
  animation-delay: 0.63s;
}
.l-5 {
  animation-delay: 0.69s;
}
.l-6 {
  animation-delay: 0.74s;
}
.l-7 {
  animation-delay: 0.79s;
}
.l-8 {
  animation-delay: 0.84s;
}
.l-9 {
  animation-delay: 0.89s;
}
.l-10 {
  animation-delay: 0.94s;
}

@keyframes loadingF {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.js-loadHide {
  display: none !important;
}

.loader {
  margin: 0 0 2em;
  height: 100px;
  width: 20%;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;
}

/* Set the color of the icon */
svg path,
svg rect {
  fill: #69b748;
}

/* #Navigation ================================================== */
.start-header {
  opacity: 1;
  transform: translateY(0);
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
  transition: all 0.3s ease-out;
}
.start-header.scroll-on {
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 10px 0;
  transition: all 0.3s ease-out;
}
.start-header .container {
  max-width: 100%;
}
.start-header .container img {
  margin: 0;
}
@media (min-width: 1025px) {
  .start-header {
    padding: 5px 0;
  }
}

.navbar-brand {
  white-space: normal;
}
@media (min-width: 1100px) and (max-width: 1400px) {
  .navbar-brand {
    width: 25em;
    flex: none;
  }
}
@media (min-width: 1100px) and (max-width: 1300px) {
  .navbar-brand {
    width: 21em;
  }
}

@media (max-width: 1024px) {
  .mobile-brand {
    display: flex;
    margin-left: -0.5em;
    padding-right: 1.5em;
  }
  .mobile-brand-img {
    flex: none;
    max-width: 2.5em;
    margin: 0 0.5em 0 0;
  }
  .mobile-brand-txt {
    flex: 1;
  }
  .mobile-brand-txt h1 {
    font-size: 0.875em;
    margin-bottom: 0;
  }
  .mobile-brand-txt p {
    font-size: 12px;
    font-size: 0.75rem;
    color: #898989;
    margin-bottom: 0;
  }
}

.navigation-wrap {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1050;
  transition: all 0.3s ease-out;
}
@media (min-width: 1025px) {
  .navigation-wrap .navbar-collapse {
    flex: none;
    position: relative;
    z-index: 1;
  }
  .navigation-wrap .navbar-collapse::before {
    content: "";
    display: block;
    width: calc(100% + 40px);
    height: 100%;
    border-radius: 40px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #f0f0f0;
    position: absolute;
    z-index: -1;
    left: -5px;
    top: 10px;
  }
}
@media (min-width: 1020px) {
  .navigation-wrap .navbar-nav {
    margin-left: auto;
    margin-right: -40px;
    padding: 20px 30px;
    border-radius: 40px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: #69b748 4px solid;
    background-color: #fff;
  }
}
.navigation-wrap .nav-item img {
  margin: 0;
}
.navigation-wrap .nav-item.dropdown.dropdown-mega {
  position: static;
}
.navigation-wrap .nav-item.dropdown.dropdown-mega .dropdown-menu {
  width: 90%;
  top: auto;
  left: 5%;
}
.navigation-wrap .nav-item:first-child .nav-link::after {
  display: none !important;
}
@media (min-width: 1020px) {
  .navigation-wrap .nav-link {
    position: relative;
    padding: 0px 1.45em !important;
    color: #3f3f3f;
    font-size: 20px;
    font-size: 1.25rem;
  }
  .navigation-wrap .nav-link::after {
    content: "";
    display: block;
    width: 1px;
    height: 30px;
    background-color: #69b748;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -15px;
  }
  .navigation-wrap .nav-link:hover, .navigation-wrap .nav-link.active {
    color: #69b748;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .navigation-wrap .nav-link {
    padding-left: 1.15em !important;
    padding-right: 1.15em !important;
  }
}
@media (min-width: 1020px) and (max-width: 1200px) {
  .navigation-wrap .container-fluid {
    flex-direction: column;
  }
  .navigation-wrap .container-fluid .navbar-brand {
    margin-right: 0;
    margin-bottom: 0.25em;
  }
  .navigation-wrap .container-fluid .navbar-nav {
    justify-content: center !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .navigation-wrap .container-fluid .navbar-collapse,
.navigation-wrap .container-fluid .navbar-nav {
    width: 100%;
  }
  .navigation-wrap .container-fluid .navbar-nav,
.navigation-wrap .container-fluid .navbar-collapse::before {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
  }
  .navigation-wrap .container-fluid .navbar-collapse::before {
    width: calc(100% + 10px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.dropdown-menu.show {
  animation: fadeIn 0.3s alternate;
}

.navbar-toggler {
  border: none;
  padding: 0;
  outline: none;
  position: absolute;
  right: -5px;
  top: -5px;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler .hamburger-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  z-index: 11;
}
.navbar-toggler .hamburger-toggle .hamburger {
  position: absolute;
  transform: translate(-50%, -50%) rotate(0deg);
  left: 50%;
  top: 50%;
  width: 50%;
  height: 50%;
  pointer-events: none;
}
.navbar-toggler .hamburger-toggle .hamburger span {
  width: 100%;
  height: 4px;
  position: absolute;
  background: #333;
  border-radius: 2px;
  z-index: 1;
  transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), all 0.2s ease-in-out;
  left: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger span:first-child {
  top: 10%;
  transform-origin: 50% 50%;
  transform: translate(0% -50%) !important;
}
.navbar-toggler .hamburger-toggle .hamburger span:nth-child(2) {
  top: 50%;
  transform: translate(0, -50%);
}
.navbar-toggler .hamburger-toggle .hamburger span:last-child {
  left: 0px;
  top: auto;
  bottom: 10%;
  transform-origin: 50% 50%;
}
.navbar-toggler .hamburger-toggle .hamburger.active span {
  position: absolute;
  margin: 0;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:first-child {
  top: 45%;
  transform: rotate(45deg);
}
.navbar-toggler .hamburger-toggle .hamburger.active span:nth-child(2) {
  left: 50%;
  width: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:last-child {
  top: 45%;
  transform: rotate(-45deg);
}

.nabar-icon-mobile {
  flex: 1;
  display: inline-flex;
  align-items: center;
  width: 11em;
  justify-content: flex-end;
  margin-top: -10px;
}
.nabar-icon-mobile .icon {
  margin-left: 5px;
}
.nabar-icon-mobile .icon-search, .nabar-icon-mobile .icon-inquiry {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}
.nabar-icon-mobile .icon-search img, .nabar-icon-mobile .icon-inquiry img {
  width: 30px;
}
.nabar-icon-mobile .icon-search small, .nabar-icon-mobile .icon-inquiry small {
  line-height: 1.5;
}

.icons {
  display: inline-flex;
  margin-left: auto;
}
.icons a {
  transition: all 0.2s ease-in-out;
  padding: 0.2rem 0.4rem;
  color: #ccc !important;
  text-decoration: none;
}
.icons a:hover {
  color: white;
  text-shadow: 0 0 30px white;
}

footer .wrapper-1550 {
  align-items: flex-start;
}
footer .footer-top, footer .footer-bottom {
  display: flex;
}
footer .footer-top {
  background-color: #32949f;
  padding-top: 20px;
  padding-bottom: 20px;
}
footer .footer-bottom {
  justify-content: center;
  background-color: #4b4a4a;
  color: #fff;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 100;
  line-height: 1.5;
}
@media (max-width: 1024px) {
  footer .footer-bottom {
    display: block;
  }
}
footer .footer-title {
  font-size: 1em;
  margin-bottom: 0.25em;
}
footer .footer-title span {
  border-bottom: #A8B2B9 1px solid;
  padding-bottom: 7px;
}
@media (min-width: 1025px) {
  footer .footer-title {
    margin-bottom: 0.75em;
  }
}
footer .footer-info {
  font-size: 0.875em;
  font-weight: 100;
}
footer .footer-info p {
  margin-bottom: 0.5em;
}
footer .footer-info.copyright p {
  font-size: 14px;
  font-size: 0.875rem;
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  footer .footer-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  footer .footer-info p {
    display: flex;
    flex-wrap: wrap;
  }
  footer .footer-info.copyright {
    justify-content: center;
  }
}
footer .footer-icon {
  justify-content: flex-end;
  margin-bottom: 0.5em;
}
footer .footer-iconLink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5em;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #3b5d73;
  overflow: hidden;
}
footer .footer-iconLink .svg {
  filter: invert(80%) sepia(10%);
}
footer .footer-link a {
  display: block;
  font-size: 0.875em;
  margin-bottom: 0.5em;
}
@media (max-width: 1024px) {
  footer .footer-link {
    display: flex;
    justify-content: space-between;
  }
}
footer .footer-txt {
  color: #000;
}
footer .footer-txt .icon-txt {
  color: #1A251F;
}
footer .footer-txt .icon {
  margin-top: -4px;
}
footer .footer-logo {
  display: flex;
  align-items: center;
}
footer .footer-logo img {
  display: block;
  max-width: 100%;
}
footer .footer-logo-img {
  margin-right: 10px;
}
footer .footer-info-wrap {
  width: 100%;
}
footer .footer-info-wrap li {
  flex: auto;
}
@media (min-width: 1025px) {
  footer .footer-info-wrap li {
    flex: none;
    margin: 0 1em;
  }
}
@media (min-width: 1025px) {
  footer .footer-info-wrap {
    flex-wrap: nowrap;
    align-items: center;
  }
}
@media (max-width: 1024px) {
  footer .footer-info-wrap {
    flex: auto;
    width: 100%;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  footer .footer-info-wrap li {
    flex: auto;
    width: 100%;
  }
  footer .footer-info-wrap li + li {
    margin-top: 0.5em;
  }
}
@media (max-width: 1024px) {
  footer .footer-info-bottom {
    margin-top: 1.5em !important;
  }
  footer .footer-info-bottom .footer-icon,
footer .footer-info-bottom .footer-info,
footer .footer-info-bottom .copyright p {
    justify-content: center;
  }
  footer .footer-info-bottom .copyright {
    flex-direction: column;
  }
}
@media (max-width: 1024px) {
  footer {
    margin-bottom: 3em;
  }
}

.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
}
@media (min-width: 800px) and (max-width: 1000px) {
  .divider {
    display: none;
  }
}

.iware {
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
  opacity: 0.5;
}
.iware,
.iware a {
  color: #000;
}
.iware a:hover {
  text-decoration: underline;
}
.iware b {
  font-weight: 100;
}

.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
}
.float-link .link-btn a:hover {
  opacity: 0.65;
}
@media (min-width: 1025px) {
  .float-link .link-btn.nabar-icon-mobile {
    display: none;
  }
}
.float-link .link-btn.nabar-icon-mobile img {
  max-width: 100%;
}
@media (max-width: 1024px) {
  .float-link .link-btn-desktop {
    display: none;
  }
}
.float-link.active {
  right: 5px;
}
@media (max-width: 1600px) {
  .float-link .link-btn a span {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .float-link.active {
    right: 10px;
  }
}
@media (max-width: 1024px) {
  .float-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0 !important;
    background: #fff;
    padding: 5px;
    margin: 0;
  }
  .float-link .link-btn {
    justify-content: center;
    margin: 0;
  }
  .float-link .link-btn .link-btn-toggle {
    position: relative;
  }
  .float-link .link-btn .icon small {
    font-size: 100%;
  }
}

.cookies {
  display: none;
  width: 100%;
  position: fixed;
  background: white;
  border-top: 1px solid #aeadad;
  color: #333;
  font-size: 15px;
  font-size: 0.9375rem;
  font-size: 0.9375rem;
  text-align: center;
  bottom: 0;
  left: 0;
  z-index: 9999;
  padding: 0.5rem 1rem;
}
.cookies > p {
  font-weight: 400;
  line-height: 2;
}
.cookies > p a {
  text-decoration: underline !important;
  color: blue;
  font-weight: 500;
}
.cookies > p .btn {
  color: #fff;
  display: inline;
  font-size: 13px;
  font-size: 0.8125rem;
  text-decoration: none !important;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.cookies.display {
  display: block;
  animation: cookies 1s 1;
}
@media (max-width: 810px) {
  .cookies {
    width: calc(100% - 1em);
    margin: 0.5em;
    border-top: none;
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.cookies-close {
  transition: all 0.2s ease-out;
  transform: translate3d(0, 100%, 0);
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}
.article,
article {
  width: 100%;
  font-size: 100%;
}
.article iframe,
article iframe {
  width: 100%;
  height: 750px;
}
@media (max-width: 810px) {
  .article iframe,
article iframe {
    height: 350px;
  }
}

.g-recaptcha iframe {
  height: auto !important;
}

.page-title, .page-title-content, .page-content {
  background-repeat: no-repeat;
  background-position: top;
  background-size: auto;
  background-repeat: repeat-y;
  background-image: url("../images/bg-page.jpg");
}

@media (min-width: 1020px) and (max-width: 1500px) {
  .page-content {
    padding: 1em;
  }
}
.page-title {
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  padding: 1.5em 0;
  z-index: 3;
  width: 100%;
  max-width: 65em;
  text-align: center;
}
.page-title-content {
  justify-content: center;
}
.page-title-content.main {
  justify-content: space-between;
  padding-top: 1em;
}
@media (max-width: 1024px) {
  .page-title-content.main {
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 0;
  }
}
@media (min-width: 1025px) {
  .page-title {
    top: -1.5em;
    padding-left: 3em;
    padding-right: 3em;
  }
}

.in-box {
  overflow: hidden;
}

.js-open {
  display: block !important;
  margin-top: 5px;
}

.lh1-45 {
  line-height: 1.45;
}

.mainContent {
  overflow: hidden;
}
.mainContent-wrap {
  padding-bottom: 2em;
}
@media (max-width: 1024px) {
  .mainContent-wrap {
    padding-top: 1em;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1025px) {
  .mainContent-info {
    width: 260px;
  }
  .mainContent-info ~ .mainContent-list {
    max-width: calc(100% - 260px);
    margin-left: auto;
    padding-left: 80px;
    padding-right: 1em;
  }
}
@media (min-width: 1101px) and (max-width: 1350px) {
  .mainContent-info {
    width: 240px;
  }
  .mainContent-info ~ .mainContent-list {
    padding-left: 1em;
  }
}
@media (max-width: 1024px) {
  .mainContent-info .headingLink-title,
.mainContent-info .link-wrap {
    display: none;
  }
}
.mainContent-list {
  max-width: 100%;
  flex: 1;
  padding-top: 1em;
}
@media (min-width: 1025px) {
  .mainContent-list {
    padding-top: 50px;
  }
}
@media (max-width: 1024px) {
  .mainContent-list {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .mainContent {
    padding-left: 2em;
    padding-right: 2em;
  }
}
@media (max-width: 1024px) {
  .mainContent {
    flex-direction: column;
  }
}

.headingLink-title {
  position: relative;
  display: inline-flex;
  align-items: flex-end;
  width: 100%;
  line-height: 1.3;
  margin-bottom: 1px;
  background-color: #7eba2e;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 0.65em 1em 0.65em 1.25em;
}
.headingLink-title .ch {
  font-size: 23px;
  font-size: 1.4375rem;
  color: #fff;
  margin-bottom: 0;
}
.headingLink-title .en {
  font-size: 11px;
  font-size: 0.6875rem;
  color: #bedd95;
  padding-left: 10px;
  font-family: Helvetica;
}

.link-wrap ul > li > a {
  position: relative;
  display: block;
  font-size: 17px;
  font-size: 1.0625rem;
  line-height: 1.5;
  color: #3f3f3f;
  border-bottom: 1px solid #a9a9a9;
  padding: 0.75em 0rem 0.75rem 50px;
}
.link-wrap ul > li > a::after {
  content: "\f067";
  font-family: "fontAwesome";
  display: block;
  color: #cacaca;
  position: absolute;
  left: 25px;
  top: 50%;
  margin-top: -10px;
  font-weight: 100;
  font-size: 14px;
  font-size: 0.875rem;
}
.link-wrap ul > li > a:hover, .link-wrap ul > li > a.active {
  background-color: #69b748;
  color: #fff;
}
.link-wrap ul > li > a .icon {
  position: absolute;
  left: 15px;
  top: 10px;
}
.link-wrap ul > li > a .icon img {
  mix-blend-mode: difference;
  opacity: 0.2;
  transition: 0.25s;
}
.link-wrap ul > li > a:hover::after, .link-wrap ul > li > a.active::after {
  color: #fff;
}
.link-wrap ul > li > a:hover .icon img, .link-wrap ul > li > a.active .icon img {
  mix-blend-mode: normal;
  opacity: 1;
}
.link-wrap ul > li > ol {
  display: none;
}
.link-wrap ul > li > ol.active {
  display: block;
}
.link-wrap ul > li > ol li {
  background-color: #e6eaea;
  padding-left: 50px;
  margin-bottom: 1px;
}
.link-wrap ul > li > ol a {
  display: block;
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  position: relative;
}
.link-wrap ul > li > ol a::before {
  content: "\f054";
  font-family: FontAwesome;
  color: #69b748;
  font-size: 8px;
  font-size: 0.5rem;
  display: block;
  position: absolute;
  top: 1.3rem;
  left: -0.75rem;
}
.link-wrap ul > li > ol a:hover, .link-wrap ul > li > ol a:hover::before, .link-wrap ul > li > ol a.active, .link-wrap ul > li > ol a.active::before {
  color: #69b748;
  font-weight: 600;
}
.link-wrap.pro ul > li > a::after {
  display: none;
}
@media (min-width: 1101px) and (max-width: 1400px) {
  .link-wrap a {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media (max-width: 1024px) {
  .link-wrap {
    margin-bottom: 1rem;
  }
}

.main-banner {
  overflow: hidden;
  z-index: 1;
}
.main-banner img {
  width: 100%;
}
.main-banner-text .title-main {
  justify-content: space-around;
}
.main-banner-text .title-btn {
  text-align: center;
}
@media (min-width: 1025px) {
  .main-banner-text {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .main-banner-text.right {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    right: 5em;
  }
  .main-banner-text.right .title-main {
    writing-mode: vertical-rl;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 2.5em;
  }
  .main-banner-text.right .title-main p {
    letter-spacing: 4px;
    margin: 0;
  }
  .main-banner-text.right .title-main span.text-main {
    display: inline-block;
    line-height: 100%;
    padding: 0.15em 0.2em 0 0;
  }
  .main-banner-text.right .title-btn {
    order: -1;
    writing-mode: vertical-rl;
    margin: 2em 2em 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }
  .main-banner-text.right .title-btn .btn {
    font-size: 24px;
    font-size: 1.5rem;
    padding: 1.3em 0.55em 1em !important;
    border-color: #000;
    letter-spacing: 4px;
  }
  .main-banner-text.right .title-btn .btn:hover {
    border-color: #69b748;
  }
  .main-banner-text.middle .title-main {
    line-height: 1.5;
  }
  .main-banner-text.middle .btn-wrap {
    margin-top: 3em;
  }
}
@media (max-width: 1024px) {
  .main-banner-text * {
    color: #000 !important;
  }
  .main-banner-text *::before {
    background-color: #000 !important;
  }
  .main-banner-text .btn {
    border-color: #000 !important;
  }
}

.text-mainRadius, .content-item, .img-mainRadius {
  border-radius: 15px !important;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.borderMain, .borderSmall {
  border: #3f3f3f 1px solid !important;
}
.borderIn {
  border: 1px solid #d2d2d2;
}
.borderMainCont {
  font-size: 17px;
  font-size: 1.0625rem;
  color: #fff;
  font-weight: 600;
  line-height: 1.5;
  background-color: #6ebe77;
  border-radius: 30px;
  padding: 0.5rem 1.5rem;
}
.borderNone {
  border: none !important;
}
.borderNoneTop {
  border-top: none !important;
}
.borderNoneBottom {
  border-bottom: none !important;
}
.borderSmall {
  background-color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.5rem 2.5rem;
}

.boxShadow {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1) !important;
}

.boxShadow_p20 {
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.06) !important;
}

.font-lg {
  font-size: 8.125vw;
}
.font-md {
  font-size: 6.875vw;
}
.font-sm {
  font-size: 5.625vw;
}
.font-sms {
  font-size: 4.6875vw;
}
.font-xs, .bg-item {
  font-size: 4.375vw;
}
.font-xsl {
  font-size: 4.0625vw;
}
.font-xxs {
  font-size: 3.75vw;
}
.font-main {
  font-size: 3.4375vw;
}
.font-defalut {
  font-size: 3.125vw;
}
.font-small {
  font-size: 2.8125vw;
}
@media (min-width: 700px) {
  .font-lg {
    font-size: 3rem !important;
  }
  .font-md {
    font-size: 2.5rem !important;
  }
  .font-sm {
    font-size: 2rem !important;
  }
  .font-sms {
    font-size: 1.75rem !important;
  }
  .font-xs, .bg-item {
    font-size: 1.5rem !important;
  }
  .font-xsl {
    font-size: 1.4rem !important;
  }
  .font-xxs {
    font-size: 1.15rem !important;
  }
  .font-main {
    font-size: 1.25rem !important;
  }
  .font-defalut {
    font-size: 1.1rem !important;
  }
  .font-small {
    font-size: 1rem !important;
  }
}

.text-main {
  color: #69b748 !important;
}
.text-base {
  color: #3f3f3f !important;
}
.text-underLine {
  text-decoration: underline;
}
.text-link {
  color: #1d2088;
}
.text-normal {
  color: #5d5d5e;
}

.bg-intro {
  background-color: #eeeae9;
}
.bg-second {
  background-color: #eeeeee !important;
}
.bg-content {
  background-color: #ECECEC;
}
.bg-item {
  background-color: #353535;
  color: #fff;
  padding: 0.5em 1.25em;
}
@media (max-width: 1024px) {
  .bg-item {
    font-weight: lighter;
    font-size: 20px !important;
    padding: 0.25em 0.75em;
  }
}
.bg-style01 {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-image: url("../images/bg-style01.jpg");
}
.bg-contentHelp {
  background-color: #e0e0e0;
}
.bg-contentEee {
  background-color: #eeeeee;
}

.flex-none {
  flex: none !important;
}
.flex-auto {
  flex: auto !important;
}

.title-mainHeadingPage {
  font-weight: 600;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 1.5em;
  padding-bottom: 0.75em;
}
.title-mainHeadingPage .icon {
  vertical-align: top;
  margin: -1px 5px 0 7px;
}
.title-mainHeadingPage .title {
  flex: 1;
  color: #353535;
  font-size: 24px;
  font-size: 1.5rem;
}

.heading-title {
  position: relative;
  width: 100%;
  color: #000;
  line-height: 1.3;
  letter-spacing: 0.5px;
  margin-bottom: 30px;
}
.heading-title .title {
  font-size: 36px;
  font-size: 2.25rem;
  font-weight: lighter;
}
.heading-title .titleSub {
  margin-bottom: 2px;
}
@media (max-width: 1024px) {
  .heading-title {
    margin-bottom: 0.5em;
  }
}

.styleLine-rightMiddle {
  position: relative;
  display: flex;
  align-items: center;
}
.styleLine-rightMiddle * {
  flex: none;
  margin-bottom: 0;
}
.styleLine-rightMiddle::after {
  content: " ";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e2e2e2;
}
.styleLine-under {
  position: relative;
  text-align: center;
  padding-bottom: 0.5em;
}
.styleLine-under::before {
  content: "";
  width: 60px;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -30px;
  background-color: #000;
}
.styleLine-verticle {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.styleLine-verticle::before {
  content: "";
  display: block;
  width: 1px;
  height: 30px;
  background-color: #000;
  margin-bottom: 1em;
}
.styleLine-verticle.white::before {
  background-color: #fff;
}
.styleLine-letTop {
  position: relative;
  padding-top: 0.5em;
  font-size: 22px;
  font-size: 1.375rem;
}
.styleLine-letTop * {
  margin-bottom: 0;
}
.styleLine-letTop::after {
  content: " ";
  display: block;
  width: 40px;
  height: 4px;
  background-color: #69b748;
  position: absolute;
  top: 0;
  left: 0;
}
.styleLine-letVerticle {
  position: relative;
  padding-left: 15px;
}
.styleLine-letVerticle::before {
  content: "";
  display: block;
  width: 1px;
  height: 20px;
  background-color: #c9c9c9;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -10px;
}
.styleLine-centerMiddle {
  font-size: 30px;
  font-size: 1.875rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.styleLine-centerMiddle::before, .styleLine-centerMiddle::after {
  content: " ";
  display: block;
  border-bottom: 1px solid #aaa;
  flex: 1;
  margin: 0 1em;
}
.styleLine-centerMiddle p {
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .styleLine-centerMiddle {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.style_caretRight {
  position: relative;
  padding: 10px 0 10px 30px;
}
.style_caretRight:not(:last-child) {
  border-bottom: 1px dashed #fff;
}
.style_caretRight::before {
  content: "\f0da";
  font-family: FontAwesome;
  font-size: 1.25em;
  position: absolute;
  left: 0;
  top: 0.3em;
}

.block-title-box {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.25;
  margin-bottom: 1.25rem;
  padding-left: 0.5em;
  font-weight: 500;
  z-index: 2;
}
.block-title-box .txt {
  flex: none;
  margin-bottom: 0;
}
.block-title-box::before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: #69b748 2px solid;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: -15px;
}
.block-title-box::after {
  content: "";
  display: inline-block;
  width: 130px;
  height: 1px;
  background-color: #69b748;
  margin-left: 0.5em;
}

.titleStyle {
  display: block;
  width: 100%;
}
.titleStyle::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #d6d6d6;
  position: absolute;
  top: 0px;
  z-index: 1;
}
.titleStyle::after {
  content: "";
  display: block;
  width: 30px;
  height: 21px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../images/icon_style.png");
  background-color: #fff;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
}
.titleStyle.gray::after {
  background-color: #eee;
}

.style__underLine {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: #fff;
}
.style__underLine__small {
  border-top: none;
  border-bottom-width: 2px;
  border-bottom-style: dotted;
}
.style__dot {
  position: relative;
  padding-left: 2rem !important;
  line-height: 1.5;
}
.style__dot:not(:last-of-type) {
  margin-bottom: 0.5em;
}
.style__dot::before {
  content: "";
  width: 7px;
  height: 7px;
  background-color: #000;
  border-radius: 100%;
  position: absolute;
  left: 15px;
  top: 10px;
  transform: translateY(0);
}

.content-item {
  position: relative;
  justify-content: center;
  border: #dddddd 1px solid;
  overflow: hidden;
  transition: 0.25s;
  height: 0;
  padding: 1.65em 0.9em 46%;
}
.content-item .num {
  display: inline-block;
  font-size: 18px;
  font-size: 1.125rem;
  color: #353535;
  line-height: 1.25;
  background-color: #eeeeee;
  text-align: center;
  padding: 10px 15px;
  border-radius: 12px;
  position: absolute;
  left: -7px;
  top: -3px;
}
.content-item .info {
  text-align: center;
  color: #353535;
}
@media (max-width: 1024px) {
  .content-item .info {
    padding: 1em 0.5em;
  }
}
.content-item .title {
  font-weight: 900;
  font-size: 3.75vw;
}
@media (min-width: 1025px) {
  .content-item .title {
    font-size: 1.8947368421vw;
  }
}
@media (min-width: 1055px) and (max-width: 1100px) {
  .content-item .title {
    font-size: 1.7368421053vw;
  }
}
@media (min-width: 800px) and (max-width: 1050px) {
  .content-item .title {
    font-size: 2.34375vw;
  }
}
.content-item .txt {
  margin-bottom: 0;
  font-size: 3.125vw;
  line-height: 1.3;
}
@media (min-width: 1025px) {
  .content-item .txt {
    font-size: 0.8947368421vw;
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .content-item .txt {
    font-size: 1.1578947368vw;
  }
}
@media (min-width: 1055px) and (max-width: 1100px) {
  .content-item .txt {
    font-size: 1.3157894737vw;
  }
}
@media (min-width: 800px) and (max-width: 1050px) {
  .content-item .txt {
    font-size: 2.5vw;
  }
}
@media (min-width: 1025px) {
  .content-item {
    background-color: #fff;
  }
}
@media (min-width: 800px) and (max-width: 1600px) {
  .content-item {
    height: auto;
    min-height: 8rem;
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 1024px) {
  .content-item {
    height: auto;
    border-radius: 10px;
    padding: 0 !important;
  }
}
.content-link {
  position: relative;
  display: block;
  width: 100%;
}
@media (min-width: 1025px) {
  .content-link::before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    position: absolute;
    bottom: -17px;
    left: 50%;
    margin-left: -9px;
    transition: 0.25s;
    z-index: 2;
  }
  .content-link::after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-color: #69b748;
    border-radius: 50%;
    position: absolute;
    left: calc(100% + 1.1em);
    top: 50%;
    margin-top: -3.5px;
  }
}
@media (min-width: 1025px) and (max-width: 1024px) {
  .content-link::after {
    display: none;
  }
}
@media (min-width: 1025px) {
  .content-link:hover::before, .content-link.active::before {
    background-image: url("../images/content-link-active.png");
  }
  .content-link:hover .content-item, .content-link.active .content-item {
    border-color: #69b748;
  }
  .content-link:hover .content-item .title, .content-link.active .content-item .title {
    color: #69b748;
  }
}
.content-nav {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.content-nav-list {
  flex: 1;
}
.content-nav-list:last-child .content-link::after {
  display: none;
}
@media (min-width: 1025px) {
  .content-nav-list + .content-nav-list {
    padding-left: 2.5em;
  }
}
@media (max-width: 1024px) {
  .content-nav-list {
    flex: 0 0 calc(50% - 1em);
    margin: 0 0.5em 1.5em;
  }
}
.content-nav.small {
  justify-content: center;
}
.content-nav.small .content-nav-list {
  position: relative;
  flex: none;
}
.content-nav.small .content-link::after {
  left: calc(100% + 1em);
}
.content-nav.small .content-item {
  min-height: auto;
  height: auto;
  padding: 1.15em 2em;
}
.content-nav.small .content-item .title {
  font-size: 3.75vw;
  font-weight: 500;
}
@media (min-width: 1025px) {
  .content-nav.small .content-item .title {
    font-size: 1.1578947368vw;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .content-nav.small .content-item .title {
    font-size: 1.4736842105vw;
  }
}
@media (min-width: 1100px) and (max-width: 1300px) {
  .content-nav.small .content-item .title {
    font-size: 1.7368421053vw;
  }
}
@media (max-width: 1024px) {
  .content-nav.small .content-item .title br {
    display: none;
  }
}
.content-nav.small-noBorder .content-nav-list {
  flex: none;
}
.content-nav.small-noBorder .content-item {
  border: none;
}
.content-nav.small-noBorder .content-item .title {
  color: #606060;
  font-size: 3.4375vw;
}
@media (min-width: 1100px) {
  .content-nav.small-noBorder .content-item .title {
    font-size: 1.1578947368vw;
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .content-nav.small-noBorder .content-item .title {
    font-size: 21px;
    font-size: 1.3125rem;
  }
}
@media (min-width: 900px) and (max-width: 1100px) {
  .content-nav.small-noBorder .content-item .title {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .content-nav.small-noBorder .content-item {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.content-nav.small-noBorder .content-link::before {
  width: 72px;
  height: 10px;
  bottom: 0;
  margin-left: -36px;
  transition: 0s;
}
.content-nav.small-noBorder .content-link::after {
  display: none;
}
.content-nav.small-noBorder .content-link:hover::before, .content-nav.small-noBorder .content-link.active::before {
  background-image: url("../images/content-line-active.png");
  bottom: 3px;
}
.content-nav.small-noBorder .content-link:hover::after, .content-nav.small-noBorder .content-link.active::after {
  display: block !important;
  width: 100%;
  height: 2px;
  border-radius: 0;
  left: 0;
  bottom: 10px;
  top: auto;
}
@media (max-width: 1024px) {
  .content-nav.small-noBorder .content-link:hover::before, .content-nav.small-noBorder .content-link.active::before {
    bottom: 0;
  }
  .content-nav.small-noBorder .content-link:hover::after, .content-nav.small-noBorder .content-link.active::after {
    bottom: 7px;
  }
}
@media (min-width: 1025px) {
  .content-nav.small.small-xs .content-item .title {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.content-nav.small.default .content-nav-list {
  flex: 1 0 auto !important;
  max-width: 100% !important;
}
@media (min-width: 1025px) {
  .content-nav.small.default .content-item {
    min-height: 100px;
  }
}
@media (max-width: 1024px) {
  .content-nav.small.default .content-nav-list {
    margin: 5px;
  }
  .content-nav.small.default .content-item .info {
    padding: 0.5em 1em;
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .content-nav.small .content-item {
    min-height: auto;
  }
}
@media (max-width: 1024px) {
  .content-nav.small {
    justify-content: space-around;
    margin-bottom: 1em;
  }
  .content-nav.small .content-link:hover::before, .content-nav.small .content-link:hover::after, .content-nav.small .content-link.active::before, .content-nav.small .content-link.active::after {
    display: none !important;
  }
  .content-nav.small .content-link:hover .title, .content-nav.small .content-link.active .title {
    color: #000;
  }
}
@media (max-width: 1024px) {
  .content-nav {
    justify-content: flex-start !important;
    margin-bottom: 0 !important;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .content-nav-list {
    flex: 0 0 calc(33.3333333333% - 4px) !important;
    max-width: calc(33.3333333333% - 4px) !important;
    margin: 0 2px 5px 2px;
    background: #eee;
  }
  .content-nav-list * {
    border: none;
  }
}
.content-nav-dropdown {
  min-width: 100vw;
  padding: 1em 0.25em;
  margin-bottom: 1.5em;
  background: #E5E5E5;
  position: absolute;
  left: 0.75em;
  top: 7em;
  z-index: 1;
}
.content-nav-dropdown::before {
  content: "";
  display: block;
  width: 1000%;
  height: 100%;
  background: #E5E5E5;
  position: absolute;
  left: -500%;
  top: 0;
  z-index: 0;
}
.content-nav-dropdown-container {
  position: relative;
  z-index: 1;
  width: 100%;
}
.content-nav-dropdown-container li {
  position: relative;
  padding: 0 2em;
}
.content-nav-dropdown-container li::after {
  content: "";
  width: 1px;
  height: 15px;
  background-color: #5F5F5F;
  position: absolute;
  left: 100%;
  top: 50%;
  margin-top: -7.5px;
}
@media (max-width: 1024px) {
  .content-nav-dropdown-container li {
    padding-left: 1em;
    padding-right: 1em;
  }
  .content-nav-dropdown-container li::after {
    margin-top: -4.5px;
  }
}
.content-nav-dropdown-container a {
  font-size: 3.75vw;
  line-height: 1.5;
  color: #6c757d;
}
.content-nav-dropdown-container a:hover, .content-nav-dropdown-container a.active {
  color: #2B2B2B;
}
@media (min-width: 1025px) {
  .content-nav-dropdown-container a {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media (max-width: 1024px) {
  .content-nav-dropdown {
    position: relative;
    left: 0;
    top: 0;
    padding-top: 0.25em;
    padding-bottom: 0.5em;
    margin-bottom: 0;
  }
  .content-nav-dropdown-container a {
    font-size: 3.59375vw;
  }
}
.content-nav-dropdown.normal {
  position: relative;
  top: 0;
  left: 0;
  min-width: auto;
  overflow: hidden;
}
.content-nav-dropdown.normal .content-nav-dropdown-container {
  justify-content: center;
}
@media (max-width: 1024px) {
  .content-link.active::before, .content-link.active::after {
    display: none !important;
  }
}

.tag-content {
  text-align: center;
}
.tag-content a {
  border-radius: 25px;
  margin-left: 6px;
  margin-right: 6px;
}
.tag-content a.active {
  border-color: #69b748;
  background-color: #69b748;
  color: #fff;
}
@media (max-width: 1024px) {
  .tag-content a {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 3.125vw;
  }
}

.select_style::after {
  content: "\f0d7";
  font-family: "fontAwesome";
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  position: absolute;
  right: 8px;
  top: 1px;
}
.select_style select.form-control {
  border: 1px solid #e6eaea !important;
  background: #e6eaea;
  text-align: left;
  border-radius: 0;
  height: 34px !important;
  padding-top: 0;
  padding-bottom: 0;
}
.select_style select {
  width: 100%;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
}
.select_style select,
.select_style input {
  border: none !important;
  height: 100% !important;
}
.select_style .form-control {
  border-radius: 0;
}
.select_style .disabled {
  background-color: #e9ecef;
}
.select_style .disabled select,
.select_style .disabled input {
  background: transparent;
}

.count_style {
  border: 1px solid #e6eaea !important;
  background: #e6eaea;
  text-align: left;
  border-radius: 0;
  display: flex;
  align-items: center;
  padding: 3px;
}
.count_style .item .form-control {
  height: auto;
  padding: 0;
  background-color: transparent;
  text-align: center;
}
.count_style .add,
.count_style .minus {
  width: 30px;
  flex: none;
  text-align: center;
  padding: 0;
}
.count_style .add.btn .fa,
.count_style .minus.btn .fa {
  font-size: 14px;
  font-size: 0.875rem;
  margin: 0 !important;
  color: #3f3f3f;
}

.nav-tabs.main {
  border-bottom: none;
  margin-top: 2em;
}
.nav-tabs.main .nav-item {
  flex: 1;
  text-align: center;
  margin: 0 0.25em;
}
.nav-tabs.main .nav-item::after {
  display: none;
}
.nav-tabs.main .nav-link {
  width: 100%;
  border-radius: 10px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #A1A1A1 #A1A1A1 white #A1A1A1;
  margin-bottom: 0px;
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
  background-color: #eee;
}
.nav-tabs.main .nav-link:hover, .nav-tabs.main .nav-link.active {
  background: #69b748;
  color: #fff !important;
  border-color: #69b748;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.nav-tabs.main.main_underLine {
  max-width: 100%;
}
.nav-tabs.main.main_underLine .nav-item {
  margin-bottom: 1em;
}
.nav-tabs.main.main_underLine .nav-item::before {
  display: none;
}
.nav-tabs.main.main_underLine .nav-link {
  color: #616161;
  border-width: 0 0 1px 0;
  border-radius: 0;
  border-bottom-color: #898989;
}
.nav-tabs.main.main_underLine .nav-link:hover, .nav-tabs.main.main_underLine .nav-link.active {
  color: #000 !important;
  background-color: transparent;
  border-bottom-width: 3px;
}
@media (max-width: 810px) {
  .nav-tabs.main.main_underLine {
    margin-top: 0;
  }
  .nav-tabs.main.main_underLine + .tab-content {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.breadcrum-wrap .breadcrumb {
  font-size: 16px;
  font-size: 1rem;
  color: #404040;
  background-color: transparent;
  margin-bottom: 0;
  padding: 0.75rem;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 0em;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "";
  display: block;
  width: 30px;
  height: 13px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 13px;
  background-image: url("../images/icon-arrow-right01.png");
  padding-top: 1.7em;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item.active {
  color: black;
}
@media (min-width: 1101px) and (max-width: 1350px) {
  .breadcrum-wrap .breadcrumb {
    padding-left: 3em;
  }
}
.breadcrum-wrap .icon {
  margin-top: -7px;
}
@media (max-width: 1024px) {
  .breadcrum-wrap {
    padding-left: 10px;
  }
}

.pagination-wrap {
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.pagination-wrap .pagination {
  justify-content: center;
}
.pagination-wrap .pagination .page-link {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #6e6d6d;
  font-size: 16px;
  font-size: 1rem;
  font-family: "Roboto";
  background-color: transparent;
  border-radius: 4px;
  padding: 0;
  margin-right: 0.5rem;
}
.pagination-wrap .pagination .page-link:hover, .pagination-wrap .pagination .page-link.active {
  color: #fff;
  background-color: #3f3f3f;
  border-color: #3f3f3f;
}
.pagination-wrap .pagination-select .select_style {
  width: 100%;
}
.pagination-wrap .pagination-select .select_style::after {
  top: 5px;
}
@media (min-width: 1025px) {
  .pagination-wrap .pagination-select {
    width: 60px;
  }
}
@media (max-width: 1024px) {
  .pagination-wrap .pagination-select {
    display: flex;
    justify-content: center;
    width: 175px;
  }
}
.pagination-wrap .fa {
  font-size: 1.2em;
}
@media (max-width: 1024px) {
  .pagination-wrap {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.tag-item {
  display: inline-block;
  width: auto;
  line-height: 1.1;
  margin: 2.5px;
  font-size: 14px;
  line-height: 1;
  padding: 6px;
  text-align: center;
  background-color: #eeeeee;
  color: #353535;
}
.tag-item.tag {
  color: #4D4D4D;
  background-color: #e5e5e5;
  line-height: 25px;
  padding: 0.5em 1em;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 14px;
  font-size: 0.875rem;
}
@media (min-width: 1025px) {
  .tag-item.tag {
    font-size: 18px;
    font-size: 1.125rem;
    padding: 8px 24px;
  }
}
.tag-item.main {
  background-color: #69b748;
  border-radius: 30px;
  color: #fff;
  padding: 0.5rem 1rem;
}
.tag-item.new {
  border-radius: 30px;
  background-color: #7eba2e;
  color: #fff;
  padding: 0.5rem 1rem;
}
.tag-item.new .txt {
  color: #ffe42d;
  font-size: 14px;
  font-size: 0.875rem;
  padding-left: 5px;
}
.tag-item.default {
  border: #dbdbdb 1px solid;
  background-color: #fff;
  border-radius: 10px;
  color: #5f5f5f;
  font-size: 16px !important;
  padding: 6px 12px;
}
.tag-item.dark {
  background-color: #353535;
  color: #fff;
}
.tag-item.white {
  display: inline-flex;
  align-items: center;
  border: #fff 1px solid;
  background-color: #fff;
  font-size: 16px !important;
  padding: 6px 12px;
  line-height: 1.5;
}
.tag-item.white .icon {
  margin-right: 10px;
}

.swiper-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-effects {
  position: relative;
  top: 0.5em;
}
.swiper-effects .swiper-pagination,
.swiper-effects .swiper-button-prev,
.swiper-effects .swiper-button-next {
  position: static;
  width: auto;
  margin: 0;
}
.swiper-effects .swiper-pagination {
  padding: 0 25px;
  font-family: "Roboto";
  color: #bbbbbb;
}
.swiper-effects .swiper-pagination .swiper-pagination-current {
  color: #3f3f3f;
}
.swiper-effects .swiper-button-prev::after,
.swiper-effects .swiper-button-next::after {
  font-size: 20px;
  font-size: 1.25rem;
  color: #3f3f3f;
}

.cataTxt {
  font-size: 16px;
  font-size: 1rem;
  color: #0c3766;
  line-height: 1.5;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 2.5px;
}
@media (max-width: 1024px) {
  .cataTxt {
    padding-top: 0;
    padding-bottom: 5px;
  }
}
.cataList {
  width: 100%;
  padding: 1em 1em 0.5em;
  z-index: 0;
  cursor: pointer;
}
@media (min-width: 1025px) {
  .cataList {
    margin-top: 0.5em;
  }
}
@media (max-width: 1024px) {
  .cataList {
    padding: 0.5em;
    padding-bottom: 0.25em;
  }
}
.cataList::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: #e8e8e8 1px solid;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.cataList-wrap:hover .cataList::before {
  box-shadow: 0 7px 10px -5px rgba(0, 0, 0, 0.1) !important;
}
.cataListImg {
  margin-bottom: 0.25rem;
  overflow: hidden;
  height: 9em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cataListImg img {
  max-height: 100%;
}
@media (max-width: 810px) {
  .cataListImg {
    height: 29vw;
    align-items: flex-start;
  }
}

.checkbox_style > input[type=checkbox] {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.checkbox_style > input + label {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 2px;
  margin-bottom: 5px;
  margin-right: 2px;
  margin-left: 0;
  padding-left: 1.75rem;
  cursor: pointer;
  border: none;
}
.checkbox_style > input + label::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  background-color: #E5E5E5;
}
.checkbox_style > input + label::after {
  content: "\f00c";
  font-family: FontAwesome;
  position: absolute;
  left: 2px;
  font-size: 1rem;
  color: #fff;
  opacity: 0;
}
.checkbox_style > input:checked + label::before {
  background-color: #69b748;
  border-color: #69b748;
}
.checkbox_style > input:checked + label::after {
  opacity: 1;
}
.checkbox_style p {
  margin-top: 10px;
}

.radio_style {
  margin-top: 10px !important;
}
.radio_style input[type=radio] {
  display: none;
}
.radio_style input + label {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 1;
  padding-left: 30px;
  margin-bottom: 0;
}
.radio_style input + label::before, .radio_style input + label::after {
  content: "";
  display: inline-block;
  border-radius: 50%;
}
.radio_style input + label::before {
  width: 20px;
  height: 20px;
  border: #cecece 1px solid;
  background: #fff;
  position: absolute;
  top: 1px;
  left: 0;
}
.radio_style input + label::after {
  width: 10px;
  height: 10px;
  background-color: transparent;
  position: absolute;
  left: 5px;
  top: 6px;
}
.radio_style input:checked + label::after {
  background-color: #69b748;
}
.form-group-list .item {
  flex: 1;
}
@media (max-width: 1024px) {
  .form-group-list .item {
    padding-right: 10px;
  }
}

.accordion .btn {
  position: relative;
  font-size: 4.6875vw;
  font-weight: 600;
  color: #353535;
  text-decoration: none !important;
  padding-left: 0;
}
.accordion .btn::after {
  content: "\f068";
  font-family: FontAwesome;
  position: absolute;
  right: 0;
  top: 10px;
}
.accordion .btn.collapsed::after {
  content: "\f067";
}
@media (min-width: 1025px) {
  .accordion .btn {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
.accordion .card {
  border: none;
}
.accordion .card > .card-header {
  background: transparent;
  padding-left: 0.3rem;
  padding-bottom: 0.3rem;
  margin-bottom: 0;
}
.accordion .card .card-body {
  line-height: 150%;
}
@media (min-width: 1025px) {
  .accordion .card .card-body {
    padding-left: 2.5em;
  }
}

@media (min-width: 1025px) {
  .pageBlog-content {
    padding-top: 4em;
  }
  .pageBlog-content .pageTitle {
    width: 26em;
    padding-left: 1em;
  }
  .pageBlog-content .pageIimg {
    flex: 1;
  }
  .pageBlog-content .btn {
    padding-left: 1.75em !important;
    padding-right: 1.75em !important;
    padding-bottom: 0.65em !important;
  }
  .pageBlog-content .ser-contentIntro-item-list {
    margin-bottom: 4em;
  }
  .pageBlog-content .ser-contentIntro-item-list:nth-of-type(odd) {
    margin-right: 0;
  }
  .pageBlog-content .ser-contentIntro-item-list:nth-of-type(odd) .pageTitle {
    padding-right: 5em;
  }
  .pageBlog-content .ser-contentIntro-item-list:nth-of-type(even) {
    margin-right: auto;
    margin-left: 0;
  }
  .pageBlog-content .ser-contentIntro-item-list:nth-of-type(even) .pageImg {
    order: -1;
  }
  .pageBlog-content .ser-contentIntro-item-list:nth-of-type(even) .pageTitle {
    padding-left: 5em;
  }
  .pageBlog-content .ser-contentIntro-item-list:last-of-type {
    margin-bottom: 0;
    padding-bottom: 4em;
  }
}
@media (min-width: 1100px) and (max-width: 1400px) {
  .pageBlog-content .ser-contentIntro-item-list .pageTitle {
    padding-left: 2em !important;
    padding-right: 2em !important;
  }
}
@media (max-width: 1024px) {
  .pageBlog-content {
    padding: 1em 1em 0;
  }
  .pageBlog-content .pageImg {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.min-80 {
  min-width: 80px;
  flex: none !important;
  width: auto;
}

.form-control,
.btn {
  border-radius: 0.5em;
}

.form-control {
  height: auto;
  min-height: 50px;
}
.modal {
  text-align: center;
  padding-right: 0 !important;
}
.modal-content {
  border-color: #accccd;
  border-width: 3px;
}
.modal-open {
  padding-right: 0 !important;
}
@media (min-width: 1025px) {
  .modal {
    text-align: center;
    padding: 0 !important;
  }
  .modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}
@media (min-width: 500px) {
  .modal-dialog {
    width: 100%;
    max-width: 85rem;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .modal-dialog {
    max-width: 70rem;
  }
}
.modal-autoheight .modal-body {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100px !important;
  max-height: 100vh !important;
}
@media (min-width: 500px) {
  .modal-autoheight .modal-body {
    max-height: calc(100vh - 50px) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight .modal-body {
    height: calc(90vh - 1.5rem) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight {
    transform: translateY(5%);
  }
}

.btn-secondary:hover, .btn-outline-secondary:hover {
  background-color: #69b748;
  border-color: #69b748;
  color: #fff;
}

.btn-secondary:hover, .btn-outline-secondary:hover {
  background-color: #32949f !important;
  border-color: #32949f !important;
  color: #fff !important;
}

.btn {
  font-size: 18px;
  font-size: 1.125rem;
  padding: 0.5em 1.25em;
  border-radius: 0px;
  font-weight: 100;
}
.btn .fa {
  font-weight: 100;
  vertical-align: middle;
  margin-top: 2px;
  margin-left: 6px;
}
.btn .icon {
  vertical-align: top;
  margin-top: -2px;
  margin-right: 3px;
}
.btn-white {
  color: #fff !important;
}
.btn-wrap {
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  margin: 2rem 0 1rem;
}
.btn-wrap > li {
  flex: 1;
}
.btn-wrap > li + li {
  margin-left: 1.5rem;
}
@media (max-width: 1080px) {
  .btn-wrap {
    margin-top: 1rem;
  }
  .btn-wrap > li + li {
    margin-left: 0.5rem;
  }
}
.js-toggle-btn .btn .icon {
  margin-top: 0;
}

.card {
  flex-grow: 1;
  margin-left: 1em;
  margin-right: 1em;
  border-radius: 20px;
  overflow: hidden;
}
@media (min-width: 1025px) {
  .card__3 {
    max-width: calc(33.3333333333% - 2em);
    flex: 0 0 calc(33.3333333333% - 2em);
  }
}
@media (min-width: 1025px) {
  .card__4 {
    max-width: calc(25% - 2em);
    flex: 0 0 calc(25% - 2em);
  }
}
@media (min-width: 1025px) {
  .card__horizontal {
    flex-direction: row;
  }
}
.card-wrap {
  margin-bottom: 1em;
}
@media (min-width: 1025px) {
  .card-wrap {
    margin-top: 1em;
  }
}
.card-img-top img {
  display: block;
  width: 100%;
}
.card-body {
  padding-bottom: 0;
}
.card-title {
  text-align: left;
  line-height: 1.45;
}
.card-time {
  font-family: "Roboto";
}
.card-time .date {
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: 600;
}
.card-time .year {
  margin-top: 3px;
}
.card-time .year .icon {
  margin-top: -7px;
  margin-right: 0.3em;
}
.card-time .year span {
  font-size: 18px;
  font-size: 1.125rem;
  color: #585757;
  font-weight: lighter;
  font-family: "Roboto";
}
.card-btn-list {
  margin-top: 1em;
}
.card-btn-list li {
  flex: 1;
  text-align: center;
}
@media (min-width: 1025px) {
  .card-text {
    height: 3em;
    overflow: hidden;
  }
  .card-btn-list li + li {
    border-left: 1px solid #CEC9AC;
    padding-left: 1em;
  }
}
@media (max-width: 1024px) {
  .card {
    margin-left: 0.5em;
    margin-right: 0.5em;
    max-width: calc(50% - 1em);
  }
  .card-wrap {
    margin-left: -0.5em;
    margin-right: -0.5em;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    padding: 0.25em 0.5em;
  }
  .card-title {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .card-text {
    font-size: 16px;
    font-size: 1rem;
  }
  .card-time {
    margin-bottom: 0.25em;
  }
  .card-caption {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
  }
  .card-btn .btn {
    display: block;
    width: 100%;
  }
}

.news .card {
  margin-bottom: 2em;
}
.news .card:nth-child(3n+1) {
  background-color: #ECE4E2;
}
.news .card:nth-child(3n+2) {
  background-color: #E7EBDA;
}
.news .card:nth-child(3n+3) {
  background-color: #FDEAE3;
}
.news .card-img-top {
  height: 0;
  padding-bottom: 65%;
  overflow: hidden;
}
.news .card-img-top img {
  width: auto;
  max-height: none;
}
.news .card-caption {
  padding: 0.25em;
}
@media (min-width: 1025px) {
  .news .card-caption {
    padding-top: 1.25em;
    padding-bottom: 1em;
    padding-left: 2em;
    padding-right: 2em;
  }
}
.news .card-btn-list {
  border-top: 1px solid #CEC9AC;
}
@media (min-width: 1025px) {
  .news .card-btn-list {
    padding-left: 1em;
    padding-right: 1em;
    border-bottom: 1px solid #CEC9AC;
  }
}
@media (max-width: 1024px) {
  .news .card-btn-list {
    padding-top: 0.75em;
  }
}
@media (max-width: 1024px) {
  .news .card-time {
    display: flex;
    justify-content: space-between;
  }
  .news .card-time .date {
    font-size: 25px;
    font-size: 1.5625rem;
  }
  .news .card-time .year {
    margin-top: 0;
  }
}
@media (max-width: 1024px) {
  .news .card-btn-list {
    padding-bottom: 0.6em !important;
    margin-top: auto;
  }
  .news .card-btn-list li {
    flex: auto;
    width: 100%;
  }
  .news .card-btn-list li + li {
    padding-left: 0;
  }
}

.act .card {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  border-radius: 40px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
  margin-bottom: 2em;
}
.act .card-img-top {
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
  overflow: hidden;
  height: 0;
  padding-bottom: 70%;
}
.act .card-img-top img {
  width: auto;
  max-height: none;
}
.act .card-body {
  padding: 1.5em;
}
.act .card__horizontal {
  box-shadow: none;
  margin-bottom: 1.25em;
  background-color: #ECE4E2;
}
.act .card__horizontal .card-img-top {
  border-top-left-radius: 0;
}
@media (min-width: 1025px) {
  .act .card__horizontal .card-img-top {
    flex: 0 0 680px;
  }
}
@media (min-width: 1025px) {
  .act .card__horizontal .card-body {
    padding: 3em 4em 2em;
  }
}
@media (max-width: 1024px) {
  .act .card {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
}
.act .btn-outline-secondary {
  border-color: #6F6E6C;
  color: #6F6E6C;
}

.form-check {
  margin-top: 0 !important;
}
.form-check input:checked + label::after {
  background-color: black;
}
.form-control {
  border-radius: 0;
  border-color: #9c9b9c;
}
.form-wrap label {
  margin-bottom: 0;
}
.form-flex .form-label {
  flex: none;
  width: 124px;
  padding-left: 20px;
  font-size: 18px;
  font-size: 1.125rem;
}
.form-flex .form-label + .form-flex {
  flex: 1;
}
@media (max-width: 1024px) {
  .form-flex .form-label + .form-flex .form-flex-check {
    flex-direction: row;
    margin-top: 10px;
  }
  .form-flex .form-label + .form-flex .form-flex-check .form-check {
    padding-left: 0.75em;
  }
}
.form-flex .select_style,
.form-flex .form-control {
  flex: 1;
  width: 100%;
  margin-left: 10px;
}
@media (max-width: 1024px) {
  .form-flex {
    flex-direction: column;
    align-items: flex-start;
  }
  .form-flex .form-label {
    margin-bottom: 10px !important;
  }
}

.contact .form-control {
  background-color: transparent !important;
}
.contact .select_style {
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
  border: 1px solid #9c9b9c;
  background-color: transparent;
}
.contact .select_style::after {
  top: 50%;
  transform: translateY(-50%);
}
.contact .select_style select.form-control {
  border: none !important;
}
@media (min-width: 1025px) {
  .contact {
    margin-top: 3em;
  }
}

.index-banner {
  width: 100%;
  overflow: hidden;
  margin-top: 7.1052631579vw;
}
.index-banner .swiper-pagination-bullets {
  bottom: 0 !important;
}
@media (min-width: 1025px) {
  .index-banner .swiper-pagination-bullets {
    width: auto !important;
    left: auto !important;
    bottom: auto !important;
    right: 1.5em;
    top: 50%;
    transform: translate3d(0px, -50%, 0);
    border: #fff 1px solid;
    padding: 0.5em;
    border-radius: 1em;
  }
}
@media (max-width: 1024px) {
  .index-banner .swiper-pagination-bullets {
    bottom: -1.5em !important;
  }
}
.index-banner .swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  background-color: #000;
}
.index-banner .swiper-pagination-bullet-active {
  background-color: #69b748 !important;
  border-radius: 0.75em !important;
}
@media (min-width: 1025px) {
  .index-banner .swiper-pagination-bullet {
    display: block;
    margin: 0.3em 0 !important;
    transition: height 200ms ease-in;
    background-color: #fff;
    opacity: 1;
  }
  .index-banner .swiper-pagination-bullet-active {
    height: 1.75em !important;
  }
}
@media (max-width: 1024px) {
  .index-banner .swiper-pagination-bullet-active {
    width: 1.75em !important;
    margin: 0 0.3em !important;
  }
}
.index-banner .swiper-slideInfo {
  width: 100%;
}
@media (min-width: 1025px) and (max-width: 1100px) {
  .index-banner .swiper-slideInfo .imgInfo img {
    height: 20em;
    object-fit: cover;
    object-position: left;
  }
}
@media (max-width: 1024px) {
  .index-banner .swiper-slideInfo .imgInfo img {
    height: 15em;
    object-fit: cover;
    object-position: center;
  }
}
@media (max-width: 640px) {
  .index-banner .swiper-slideInfo .imgInfo img {
    height: 7em;
  }
}
.index-banner .swiper-slideInfo .txtInfo {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 1700px;
  position: absolute;
  bottom: 0%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.index-banner .swiper-slideInfo .txtInfo .title {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1;
  margin-bottom: 15px;
}
.index-banner .swiper-slideInfo .txtInfo .txt {
  font-size: 36px;
  font-size: 2.25rem;
  line-height: 1.5;
}
.index-banner .swiper-slideInfo .txtInfo p {
  color: #fff;
  font-weight: 900;
}
.index-banner .swiper-slideInfo .txtStyle {
  top: 5.5em;
}
.index-banner .swiper-slideInfo .imgStyle {
  top: 1em;
}
@media (max-width: 1500px) {
  .index-banner .swiper-slideInfo .txtStyle {
    padding-left: 15px;
  }
}
@media (max-width: 1024px) {
  .index-banner .swiper-slideInfo .imgStyle {
    display: none;
  }
}
@media (max-width: 810px) {
  .index-banner .swiper-slideInfo .txtInfo .txt {
    font-size: 30px;
    font-size: 1.875rem;
  }
  .index-banner .swiper-slideInfo .txtInfo .title,
.index-banner .swiper-slideInfo .txtInfo .title .text-inner {
    margin-bottom: 0.25em;
  }
  .index-banner .swiper-slideInfo .txtStyle {
    top: 0;
  }
}
@media (max-width: 1024px) {
  .index-banner {
    margin-top: 11.5em;
  }
}
@media (max-width: 810px) {
  .index-banner {
    margin-top: 110px;
  }
}
.index-content {
  padding: 1em;
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .index-content {
    padding-left: 2em;
    padding-right: 2em;
  }
}
.index-pageAbout::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  max-width: 400px;
  min-height: 1005px;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  background-image: url("../images/index-sideImg.png");
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  mix-blend-mode: darken;
}
@media (max-width: 1024px) {
  .index-pageAbout::after {
    opacity: 0.1;
  }
}
.index-pageSerList-wrap {
  justify-content: center;
}
@media (min-width: 1025px) {
  .index-pageSerList-wrap {
    margin-bottom: 50px;
  }
}
.index-pageSerList-item {
  flex: 0 0 20%;
  max-width: 20%;
  text-align: center;
  padding: 0 15px;
  margin-bottom: 1.5em;
}
.index-pageSerList-item .title {
  font-size: 19px;
  font-size: 1.1875rem;
  line-height: 1.45;
  margin-top: 10px;
}
@media (max-width: 1024px) {
  .index-pageSerList-item {
    flex: 0 0 25% !important;
    max-width: 25% !important;
    padding: 0 1em;
    margin-bottom: 1.25em;
  }
}
@media (max-width: 810px) {
  .index-pageSerList-item {
    flex: 0 0 33% !important;
    max-width: 33% !important;
  }
}
@media (max-width: 640px) {
  .index-pageSerList-item {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}
.index-pageSerList-article.top {
  max-width: 72em;
}
.index-pageSerList-article.top .index-pageSerList-item {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
@media (min-width: 1100px) and (max-width: 1300px) {
  .index-pageSerList-article.top {
    max-width: 60em;
  }
}
.index-pageSerList-article.bottom {
  max-width: 87em;
}
.index-pageSerList-article.bottom .index-pageSerList-item {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}
@media (min-width: 1025px) {
  .index-pageNews {
    margin-bottom: 1em;
  }
}
@media (min-width: 1025px) {
  .index-pageAct {
    margin-bottom: 150px;
  }
}
.indexNews-swiper, .indexAct-swiper {
  position: relative;
  padding-left: 1em;
  padding-right: 1em;
  margin-bottom: 1em;
}
.indexNews-swiper .card-wrap, .indexAct-swiper .card-wrap {
  width: 100%;
  margin-top: 0 !important;
}
.indexNews-swiper .swiper-button-disabled, .indexAct-swiper .swiper-button-disabled {
  opacity: 1 !important;
  background-color: #69b748;
}
.indexNews-swiper .swiper-button-prev, .indexNews-swiper .swiper-button-next, .indexAct-swiper .swiper-button-prev, .indexAct-swiper .swiper-button-next {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: #69b748 1px solid;
}
.indexNews-swiper .swiper-button-prev::after, .indexNews-swiper .swiper-button-next::after, .indexAct-swiper .swiper-button-prev::after, .indexAct-swiper .swiper-button-next::after {
  display: none;
}
.indexNews-swiper .swiper-button-prev::before, .indexNews-swiper .swiper-button-next::before, .indexAct-swiper .swiper-button-prev::before, .indexAct-swiper .swiper-button-next::before {
  content: "";
  display: block;
  width: 43px;
  height: 17px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../images/swiper-btn-default.png");
}
.indexNews-swiper .swiper-button-prev.swiper-button-disabled::before, .indexNews-swiper .swiper-button-next.swiper-button-disabled::before, .indexAct-swiper .swiper-button-prev.swiper-button-disabled::before, .indexAct-swiper .swiper-button-next.swiper-button-disabled::before {
  background-image: url("../images/swiper-btn-active.png");
}
@media (max-width: 1024px) {
  .indexNews-swiper .swiper-button-prev, .indexNews-swiper .swiper-button-next, .indexAct-swiper .swiper-button-prev, .indexAct-swiper .swiper-button-next {
    width: 35px;
    height: 35px;
  }
  .indexNews-swiper .swiper-button-prev::before, .indexNews-swiper .swiper-button-next::before, .indexAct-swiper .swiper-button-prev::before, .indexAct-swiper .swiper-button-next::before {
    width: 20px;
    height: 15px;
  }
}
@media (max-width: 1024px) {
  .indexNews-swiper .swiper-button-prev, .indexAct-swiper .swiper-button-prev {
    left: -10px;
  }
}
.indexNews-swiper .swiper-button-next::before, .indexNews-swiper .swiper-button-next.swiper-button-disabled::before, .indexAct-swiper .swiper-button-next::before, .indexAct-swiper .swiper-button-next.swiper-button-disabled::before {
  transform: rotateY(180deg);
}
@media (max-width: 1024px) {
  .indexNews-swiper .swiper-button-next, .indexAct-swiper .swiper-button-next {
    right: -10px;
  }
}
.indexNews-swiper.full .act .card-img-top, .indexAct-swiper.full .act .card-img-top {
  height: auto;
  padding-bottom: 0;
}
.indexNews-swiper.full .act .card-img-top img, .indexAct-swiper.full .act .card-img-top img {
  position: static;
}
@media (min-width: 1025px) {
  .indexNews-swiper, .indexAct-swiper {
    padding-left: 7em;
    padding-right: 7em;
  }
}
@media (max-width: 1024px) {
  .indexNews-swiper, .indexAct-swiper {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  .indexNews-swiper.full, .indexAct-swiper.full {
    padding-left: 0;
    padding-right: 0;
  }
  .indexNews-swiper .card-wrap,
.indexNews-swiper .card, .indexAct-swiper .card-wrap,
.indexAct-swiper .card {
    margin-bottom: 0;
  }
  .indexNews-swiper .card, .indexAct-swiper .card {
    max-width: calc(100% - 1em);
  }
}
@media (min-width: 1020px) and (max-width: 1100px) {
  .indexNews-swiper.full .act .card__horizontal, .indexAct-swiper.full .act .card__horizontal {
    flex-direction: row;
  }
}
.indexNews-swiper .swiper-slide:nth-child(3n+1) .card {
  background-color: #ECE4E2;
}
.indexNews-swiper .swiper-slide:nth-child(3n+2) .card {
  background-color: #E7EBDA;
}
.indexNews-swiper .swiper-slide:nth-child(3n+3) .card {
  background-color: #FDEAE3;
}

.circle {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
}
.circle-wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-image: url("../images/indexSer-bg.jpg");
}
.circle * {
  position: relative;
  z-index: 2;
}
.circle .num {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  background-color: #449ea5;
  border-radius: 50%;
}
@media (max-width: 1024px) {
  .circle .num {
    margin-bottom: 0.25em;
  }
}

.animationItem {
  transform: translate3d(-50px, 0, 0);
  opacity: 0;
  transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}

.animationItem.animated {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.about_article {
  margin-bottom: 2.5em;
}
@media (min-width: 1025px) {
  .about_article {
    margin-bottom: 5em;
  }
}
@media (min-width: 1025px) {
  .about_txt {
    line-height: 2;
    padding-left: 3rem;
    padding-right: 1.5rem;
  }
}

.numCirlce {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 37px;
  font-size: 23px;
  font-size: 1.4375rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../images/icon-num.png");
  border-radius: 50%;
  color: #fff;
  text-align: center;
  margin-right: 15px;
  overflow: hidden;
}

.ser_link {
  flex: auto;
  display: block;
  background-color: #69b748;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.2;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: center;
  padding: 15px 30px 17px;
  margin: 0 15px 15px;
}
.ser_link_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1em;
}
@media (min-width: 1025px) {
  .ser_link_wrap {
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 3em;
  }
}
.ser_link, .ser_link:hover, .ser_link:focus {
  color: #fff;
}
.ser_link:hover {
  background-color: #32949f;
}
@media (max-width: 1024px) {
  .ser_link {
    flex: 1 0 calc(50% - 10px);
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.5;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }
}
.ser_article {
  margin-bottom: 2.5em;
}
@media (min-width: 1025px) {
  .ser_article {
    margin-bottom: 5em;
  }
}
.ser_txt {
  display: flex;
  padding: 1em;
}
@media (min-width: 1025px) {
  .ser_txt {
    padding-left: 65px;
    padding-right: 10px;
    padding-top: 30px;
    padding-bottom: 15px;
  }
}
.ser_txt_item {
  align-items: flex-start;
  font-size: 20px;
  font-size: 1.25rem;
}
.ser_txt_item .num {
  flex: none;
  display: inline-block;
  color: #6d6e6c;
  font-family: "Roboto";
  font-weight: 900;
  border-bottom: 1px solid #838482;
  padding-bottom: 10px;
  margin-right: 20px;
}
.ser_txt_item .txt {
  flex: 1;
  color: #272626;
  line-height: 1.6;
  border-bottom: 1px dashed #b9beb5;
  padding-top: 2px;
  padding-bottom: 20px;
  margin-bottom: 0.5em;
}
.ser_txt_list {
  padding-right: 1em;
}
@media (min-width: 1025px) {
  .ser_txt_list {
    flex: 1;
    padding-right: 3.6842105263vw;
  }
}
@media (min-width: 1025px) {
  .ser_txt_list.half {
    padding-right: 1.8421052632vw;
  }
}
.ser_txt_img {
  text-align: center;
}
@media (min-width: 1025px) {
  .ser_txt_img {
    flex: none;
    width: 190px;
  }
}
@media (max-width: 1024px) {
  .ser_txt {
    flex-direction: column;
    padding-top: 0;
  }
  .ser_txt_img {
    order: -1;
    margin-bottom: 15px;
  }
}

.news-box {
  background-color: #ECE4E2;
  padding: 2em 3em;
}
.news-box-title {
  justify-content: space-between;
  border-top: none !important;
  border-bottom: 1px solid #CEC9AC;
  padding-top: 0 !important;
  padding-bottom: 0.75em;
  margin-top: 0;
}
.news-box-title li + li {
  flex: none;
  margin-left: 1em;
  padding-left: 2em;
}
@media (min-width: 1025px) {
  .news-box-title h3 {
    font-size: 36px;
    font-size: 2.25rem;
  }
}
.news-box-article {
  padding: 1em;
}
@media (max-width: 1024px) {
  .news-box {
    padding: 1em;
  }
  .news-box-article {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}
.newsAct .card {
  background-color: #ECE4E2;
  box-shadow: none;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.newsAct .card-img-top {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}
.newsAct-photo .swiper-slide {
  overflow: hidden;
}
.newsAct-photo .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 1025px) {
  .newsAct-photo .swiper-slide {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}
.newsAct-photo .swiper-button-next, .newsAct-photo .swiper-button-prev {
  color: #000;
}
.newsAct-photo .swiper-button-next::after, .newsAct-photo .swiper-button-prev::after {
  font-size: 1.25em;
  font-weight: 900;
}
@media (min-width: 1025px) {
  .newsAct-photo .swiper-button-next::after, .newsAct-photo .swiper-button-prev::after {
    font-size: 1.75em;
  }
}
@media (max-width: 1024px) {
  .newsAct-photo .swiper-button-next {
    right: -10px;
  }
  .newsAct-photo .swiper-button-prev {
    left: -10px;
  }
}
.newsAct-photo .swiper-slide-ratio iframe {
  height: 100%;
}
.newsAct-photo .mySwiper2 {
  height: 80%;
  width: 100%;
}
.newsAct-photo .mySwiper2 .swiper-wrapper {
  display: flex;
  align-items: center;
}
.newsAct-photo-small {
  padding-left: 1.5em;
  padding-right: 1.5em;
  margin-top: 0.3em;
}
@media (min-width: 1025px) {
  .newsAct-photo-small {
    padding-left: 4em;
    padding-right: 4em;
    margin-top: 25px;
  }
}
.newsAct-photo .mySwiper {
  height: 20%;
  padding: 10px 0;
}
.newsAct-photo .mySwiper .swiper-slide {
  width: 25%;
  opacity: 0.4;
  height: 0;
  padding-bottom: 12%;
}
.newsAct-photo .mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
@media (max-width: 1024px) {
  .newsAct-photo .mySwiper .swiper-slide {
    padding-bottom: 15%;
  }
}
@media (max-width: 640px) {
  .newsAct-photo .mySwiper .swiper-slide {
    padding-bottom: 25%;
  }
}

.dowload-wrap {
  width: 100%;
  margin-bottom: 1rem;
}
.dowload-wrap p {
  margin-bottom: 0;
}
.dowload-wrap .btn {
  text-align: center;
  padding: 0.5rem 2.5rem;
  padding-left: 1.25rem;
  color: #8c8b8b;
  border-color: #8c8b8b;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
  background-size: auto;
  background-image: url("../images/icon-download-g.png");
  font-size: 16px;
  font-size: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dowload-wrap .btn, .dowload-wrap .btn:hover {
  background-color: #fff !important;
}
.dowload-wrap .btn:hover {
  color: black !important;
  border-color: black !important;
  background-image: url("../images/icon-download-b.png");
}
.dowload-item, .dowload-list {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  text-align: center;
}
.dowload-item {
  font-size: 18px;
  font-size: 1.125rem;
  color: #5f5f5f;
  border-bottom: 2px solid #a7a7a7;
  justify-content: space-between;
  padding-top: 10px;
}
@media (max-width: 640px) {
  .dowload-item {
    display: none;
  }
}
.dowload-list {
  font-size: 20px;
  font-size: 1.25rem;
  color: #646464;
  line-height: 1.5;
}
.dowload-list li {
  justify-content: space-between;
  padding-top: 1.75rem;
}
.dowload-list li .dowload-fileName {
  text-align: left !important;
}
@media (max-width: 640px) {
  .dowload-list {
    padding-left: 5px;
    padding-right: 5px;
  }
  .dowload-list li {
    display: block;
    text-align: left;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .dowload-list li .dowload-fileName {
    padding-left: 0;
  }
  .dowload-list li > div {
    margin-bottom: 0.5rem;
    display: flex;
    flex-wrap: wrap;
  }
}
.dowload-act {
  margin-left: 10px;
}
@media (max-width: 640px) {
  .dowload-act {
    width: 100%;
    text-align: right;
  }
}

.iconRound-more {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #e5e5e5;
}
.iconRound-more * {
  transition: 0.25s;
}
.iconRound-more .icon {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 28px;
  overflow: hidden;
}
.iconRound-more .icon img {
  position: absolute;
  top: 0;
  left: 0;
}
.iconRound-more .txt {
  color: #363535;
  font-size: 16px;
  margin-bottom: 0;
}
.iconRound-more:hover {
  background-color: #69b748;
}
.iconRound-more:hover .icon img {
  transform: translateY(-28px);
}
.iconRound-more:hover .txt {
  color: #fff;
}