footer {
  .wrapper-1550{
    @extend %setFlex;
    align-items: flex-start;
  }

  .footer {
    &-top,
    &-bottom{
      display: flex;
    }

    &-top{
      background-color: #32949f;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &-bottom{
      justify-content: center;
      background-color: #4b4a4a;
      color: #fff;
      text-align: center;
      padding-top: 1em;
      padding-bottom: 1em;

      @include fontSize(13px);
      font-weight: 100;
      line-height: 1.5;
      @include setMaxRwd{
        display: block;
      }
    }

    // 項目
    &-title{
      font-size: 1em;
      margin-bottom: 0.25em;
      span{
        border-bottom: $colorFooter 1px solid;
        padding-bottom: 7px;
      }

      @include setMinRwd{
        margin-bottom: 0.75em;
      }
    }

    &-info{
      font-size: 0.875em;
      font-weight: 100;
      p{
        margin-bottom: 0.5em;
      }

      &.copyright{
        p{
          @include fontSize(14px);
          margin-bottom: 0;
        }
      }

      @include setMaxRwd{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        p{
          display: flex;
          flex-wrap: wrap;
        }

        &.copyright{
          justify-content: center;
        }
      }
    }

    &-icon{
      @extend %setFlex-center;
      justify-content: flex-end;
      margin-bottom: 0.5em;
      &Link{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.5em;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #3b5d73;
        overflow: hidden;
        .svg{
          filter: invert(80%) sepia(10%);
        }
      }
    }

    &-link{
      a{
        display: block;
        font-size: 0.875em;
        margin-bottom: 0.5em;
      }
      @include setMaxRwd{
        display: flex;
        justify-content: space-between;
      }
    }

    &-txt{
      color:#000;
      
      .icon-txt{
        color: #1A251F;
      }

      .icon{
        margin-top: -4px;
      }
    }

    // grid
    &-logo {
      display: flex;
      align-items: center;

      img {
          display: block;
          max-width: 100%;
      }

      &-img{
        margin-right: 10px;
      }
    }

    &-info-wrap{
      @extend %setFlex;
      width: 100%;
      li{
        flex: auto;
        @include setMinRwd{
          flex: none;
          margin: 0 1em;
        }
      }

      @include setMinRwd{
        flex-wrap: nowrap;
        align-items: center;
      }

      @include setMaxRwd{
        flex: auto;
        width: 100%;
        padding-left: 0.5em;
        padding-right: 0.5em;
        li{
          &+li{
            margin-top: 0.5em;
          }
          flex: auto;
          width: 100%;
        }
      }
    }

    &-info-bottom{
      @include setMaxRwd{
        margin-top: 1.5em !important;
        .footer-icon,
        .footer-info,
        .copyright p{
          justify-content: center;
        }
        .copyright{
          flex-direction: column;
        }
      }
    }
  }

  @include setMaxRwd{
    margin-bottom: 3em;
  }
}

// 給予區塊的左右側線條使用
.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
  @include setWidthRwd(800px,1000px){
    display: none;
  }
}

.iware {
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
  opacity: 0.5;
  &,
  a{
    color:#000;
  }

  a {
      &:hover {
          text-decoration: underline;
      }
  }

  b{
    font-weight: 100;
  }
}

// Gotop 使用
.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
  .link-btn {
    a {
      &:hover {
          opacity: 0.65;
      }
    }

    &.nabar-icon-mobile{
      @include setMinRwd{
        display: none;
      }
      img{
        max-width: 100%;
      }
    }

    &-desktop{
      @include setMaxRwd{
        display: none;
      }
    }
  }

  &.active {
      right: 5px;
  }

  @include setStyleRwd(1600px){
    .link-btn {
      a {
        span{
          @include fontSize(14px);
        }
      }
    }

    &.active {
        right: 10px;
    }
  }

  @include setMaxRwd{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0 !important;
    background: #fff;
    padding: 5px;
    margin: 0;
    .link-btn {
      justify-content: center;
      margin: 0;
      .link-btn-toggle{
        position: relative;
      }

      .icon{
        small{
          font-size: 100%;
        }
      }
    }
  }
}

// Cookie Privacy
.cookies {
  display: none;
  width: 100%;
  position: fixed;
  background: white;
  border-top: 1px solid #aeadad;
  color: #333;
  @include fontSize(15px);
  font-size: 0.9375rem;
  text-align: center;
  bottom: 0;
  left: 0;
  z-index: 9999;
  padding: .5rem 1rem;
  
  & > p {
    font-weight: 400;
    line-height: 2;
    a {
      text-decoration: underline !important;
      color: blue;
      font-weight: 500;
    }
    .btn {
      color:#fff;
      display: inline;
      @include fontSize(13px);
      text-decoration: none !important;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
    }
  }
  
  &.display {
    display: block;
    animation: cookies 1s 1;
  }

  @include pad {
    width: calc(100% - 1em);
    margin: .5em;
    border-top: none;
    @include fontSize(14px);
  }

  &-close {
    transition: all 0.2s ease-out;
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}