/* #Navigation ================================================== */

$navbarTrans: all 0.3s ease-out;

.start-header {
	opacity: 1;
	transform: translateY(0);
	box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
	transition : $navbarTrans;

    &.scroll-on {
        box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
        padding: 10px 0;
        transition : $navbarTrans;
    }

    .container{
        max-width: 100%;
        img{
            margin: 0;
        }
    }

    @include setMinRwd{
        padding: 5px 0;
    }
}

.navbar-brand {
    white-space: normal;
    @include setWidthRwd(1100px,1400px){
        width: 25em;
        flex: none;
    }

    @include setWidthRwd(1100px,1300px){
        width: 21em;
    }
}

.mobile-brand{
    @include setMaxRwd{
        display: flex;
        margin-left: -0.5em;
        padding-right: 1.5em;

        &-img{
            flex: none;
            max-width: 2.5em;
            margin: 0 0.5em 0 0;
        }
        
        &-txt{
            flex: 1;
            h1{
                font-size: 0.875em;
                margin-bottom:0;
            }
    
            p{
                @include fontSize(12px);
                color: #898989;
                margin-bottom: 0;
            }
        }
    }
}

.navigation-wrap{
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1050;
	transition : $navbarTrans;

    .navbar-collapse{
        @include setMinRwd{
            flex: none;
            position: relative;
            z-index: 1;
            &::before{
                content: '';
                display: block;
                @include setSize(calc(100% + 40px),100%);
                @include radius(40px);
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                background-color: #f0f0f0;
                position: absolute;
                z-index: -1;
                left: -5px;
                top: 10px;
            }
        }
    }

    .navbar-nav{
        @include setStyleMinRwd(1020px){
            margin-left: auto;
            margin-right: -40px;
            padding: 20px 30px;
            @include radius(40px);
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border: $colorMain 4px solid;
            background-color: #fff;
        }
    }
    
    .nav-item{
        img{
            margin: 0;
        }

        &.dropdown{
            &.dropdown-mega{
                position: static;
                .dropdown-menu{
                    width: 90%;
                    top: auto;
                    left: 5%;
                }
            }
        }

        &:first-child{
            .nav-link{
                &::after{
                    display: none !important;
                }
            }
        }
    }

    .nav-link{
        @include setStyleMinRwd(1020px){
            position: relative;
            padding: 0px 1.45em !important;
            color: $colorBase;
            @include fontSize(20px);
            &::after{
                content: '';
                display: block;
                width: 1px;
                height: 30px;
                background-color: $colorMain;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -15px;
            }

            &:hover,
            &.active{
                color: $colorMain;
            }
        }

        @include setWidthRwd(1100px,1500px){
            padding-left: 1.15em !important;
            padding-right: 1.15em !important;
        }
    }

    .container-fluid{
        @include setWidthRwd(1020px,1200px){
            flex-direction: column;

            .navbar-brand {
                margin-right: 0;
                margin-bottom: 0.25em;
            }
            
            .navbar-nav {
                justify-content: center !important;
                margin-right: 0 !important;
                margin-left: 0 !important;
            }

            .navbar-collapse,
            .navbar-nav {
                width: 100%;
            }

            .navbar-nav,
            .navbar-collapse::before {
                border-top-right-radius: 40px;
                border-bottom-right-radius: 40px;
            }

            .navbar-collapse::before {
                width: calc(100% + 10px);
            }
        }
    }
}

// 參考範例 : https://codepen.io/typo3-freelancer/pen/poEvyGj
@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

// Assigning the above animation to all dropdown menus
.dropdown-menu{
    &.show{
         animation: fadeIn 0.3s alternate;
    }
}

// Complete styles for main navbar toggler and hamburger menu
.navbar-toggler{
    border: none;
    padding: 0;
    outline: none;
    position: absolute;
    right: -5px;
    top: -5px;

    &:focus{
        box-shadow: none;
    }
    .hamburger-toggle{
        position: relative;
        display: inline-block;
        width: 50px;
        height: 50px;
        z-index: 11;
        .hamburger {
            position: absolute;
            transform: translate(-50%, -50%) rotate(0deg);
            left: 50%;
            top: 50%;
            width: 50%;
            height: 50%;
            pointer-events: none;
            span {
                width: 100%;
                height: 4px;
                position: absolute;
                background: #333;
                border-radius: 2px;
                z-index: 1;
                transition: transform 0.2s cubic-bezier(0.77,0.2,0.05,1.0), background 0.2s cubic-bezier(0.77,0.2,0.05,1.0), all 0.2s ease-in-out;
                left: 0px;
                &:first-child{
                    top: 10%;
                    transform-origin: 50% 50%;
                    transform: translate(0% -50%) !important;
                }
                &:nth-child(2){
                    top: 50%;
                    transform: translate(0,-50%);
                }
                &:last-child{
                    left: 0px;
                    top: auto;
                    bottom: 10%;
                    transform-origin: 50% 50%;
                }
            }
            &.active{
                span {
                    position: absolute;
                    margin: 0;
                    &:first-child{
                        top: 45%;
                        transform: rotate(45deg);
                    }
                    &:nth-child(2){
                        left: 50%;
                        width: 0px;
                    }
                    &:last-child{
                        top: 45%;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}

// 手機置底放置ICON : gotTop & Line
.nabar-icon-mobile{
    flex: 1;
    display: inline-flex;
    align-items: center;
    width: 11em;
    justify-content: flex-end;
    margin-top: -10px;
    .icon{
        margin-left: 5px;

        &-search,
        &-inquiry{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 10px;
            img{
                width: 30px;
            }

            small{
             line-height: 1.5;
            }
        }
    }
}

.icons{
  display: inline-flex;
  margin-left: auto;
  a{
    transition: all 0.2s ease-in-out;
    padding: 0.2rem 0.4rem;
    color: #ccc !important;
    text-decoration: none;
    &:hover{
      color: white;
      text-shadow: 0 0 30px white;
    }
  }
}