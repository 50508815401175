// 按鈕Hover的狀態
%btnMainHover{
    background-color: $colorMain;
    border-color: $colorMain;
    color: #fff;
}

%btnMainHover,
%btnoutlineHover{
    background-color: $colorSubs !important;
    border-color: $colorSubs !important;
    color: #fff !important;
}

// 按鈕樣式
.btn{
    @include fontSize(18px);
    padding: 0.5em 1.25em;
    @include radius(0px);
    font-weight: 100;

    .fa{
        font-weight: 100;
        vertical-align: middle;
        margin-top: 2px;
        margin-left: 6px;
    }

    .icon {
        vertical-align: top;
        margin-top: -2px;
        margin-right: 3px;
    }

    &-white{
        color: #fff !important
    }

    // 包按鈕的框
    &-wrap {
        @extend %setFlex-center;
        flex-wrap: nowrap;
        justify-content: center;
        width: 100%;
        margin: 2rem 0 1rem;
        &>li {
            flex: 1;
            &+li {
                margin-left: 1.5rem;
            }
        }
        @include pad1080 {
            margin-top: 1rem;
            &>li {
                &+li {
                    margin-left: 0.5rem;
                }
            }
        }
    }

    // Hover{
    &-secondary,
    &-outline-secondary{
        &:hover{
            @extend %btnMainHover;
        }
    }
}

.js-toggle-btn{
    .btn{
        .icon{
            margin-top: 0;
        }
    }
}