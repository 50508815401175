.dowload{
    &-wrap{
        width: 100%;
        margin-bottom: 1rem;
        p{
            margin-bottom: 0;
        }
        
        .btn{
            text-align: center;
            padding: 0.5rem 2.5rem;
            padding-left: 1.25rem;
            color:#8c8b8b;
            border-color: #8c8b8b;
            @include setBackSet(calc(100% - 10px),auto);
            background-image: url('../images/icon-download-g.png');
            @include fontSize(16px);
            white-space:nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            &,
            &:hover{
                background-color: #fff !important;
            }
            &:hover{
                color: black !important;
                border-color:  black !important;
                background-image: url('../images/icon-download-b.png');
            }
        }
    }

    &-item,
    &-list{
        padding-left:15px;
        padding-right: 15px;
        padding-bottom: 20px;
        text-align: center;
    }

    &-item{
        @include fontSize(18px);
        color:#5f5f5f;
        border-bottom: 2px solid #a7a7a7;
        @extend %setFlex;
        justify-content: space-between;
        padding-top: 10px;
        @include mobile{
            display: none;
        }
    }

    &-list{
        @include fontSize(20px);
        color:#646464;
        line-height: 1.5;
        li{  
            @extend %setFlex;
            justify-content: space-between;
            padding-top: 1.75rem;
            .dowload-fileName{    
                text-align: left !important;
            }
        }
        
        @include mobile{
            padding-left: 5px;
            padding-right: 5px;
            li{
                display: block;
                text-align: left;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                .dowload-fileName{    
                    padding-left: 0;
                }
                &>div{
                    margin-bottom: 0.5rem;
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }
    }

    &-act{
        margin-left: 10px;
        @include mobile{
            width: 100%;
            text-align: right;
        }
    }
}


.iconRound-more{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #e5e5e5;
    *{
        transition: 0.25s;
    }
    .icon{
        position: relative;
        display: inline-block;
        width: 28px;
        height: 28px;
        overflow: hidden;
        img{
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .txt{
        color:#363535;
        font-size: 16px;
        margin-bottom: 0;
    }
    &:hover{
        background-color: $colorMain;
        .icon{
            img{
                transform: translateY(-28px);
            }
        }
        .txt{
            color:#fff
        }
    }
}

// <div class="btn-wrap text-center">
//     <a href="#" class="iconRound-more">
//         <span class="icon"><img src="assets/images/icon-more.png" alt=""></span>
//         <p class="txt">更多檔案</p>
//     </a>
// </div>