.form{
    &-check {
        margin-top: 0 !important;
        input{
            &:checked{
                & + label{
                    &::after {
                        background-color: black;
                    }
                }
            }
        }
    }

    &-control{
        border-radius: 0;
        border-color: #9c9b9c;
    }

    &-wrap{
        label{
            margin-bottom: 0;
        }
    }

    &-flex{
        @extend %setFlex-center;

        .form-label{
            flex: none;
            width: 124px;
            padding-left: 20px;
            @include fontSize(18px);

            &+.form-flex{
                flex: 1;
                @include setMaxRwd{
                    .form-flex-check{
                        flex-direction: row;
                        margin-top: 10px;
                        .form-check {
                            padding-left: 0.75em;
                        }
                    }
                }
            }
        }
        
        .select_style,
        .form-control{
            flex: 1;
            width: 100%;
            margin-left: 10px;
        }

        @include setMaxRwd{
            flex-direction: column;
            align-items: flex-start;

            .form-label{
                margin-bottom: 10px !important;
            }
        }
    }
}

// 聯絡我們
.contact{
    .form{
        &-control{
            background-color: transparent !important;
        }
    }

    .select_style{
        font-size: 1rem;
        font-weight: 400;
        color: #212529;
        border: 1px solid #9c9b9c;
        background-color: transparent;
        &::after {
            top: 50%;
            transform: translateY(-50%);
        }

        select.form-control{
            border: none !important;
        }
    }

    @include setMinRwd{
        margin-top: 3em;
    }
}
